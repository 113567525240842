import React, { Component } from "react";
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Menu,
  notification,
  Tooltip,
} from "antd";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { Link, withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Show } from "../../../contexts/PermissionContext";
import api from "../../../services/api";
import { getCompanyCurrentUser } from "../../../services/auth";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import EditDrawer from "./EditDrawer";
import ModalAddCoupon from "../ModalAddCoupon/ModalAddCoupon";
import "./index.css";
import generateFields from "./fields";
import GenerateFilter from "../../../components/GenerateFilter";
import { sorter } from "../../../utils/sorter";
import TablePagination, {
  paginationInitConfig,
} from "../../../components/TablePagination";
import { TicketIcon } from "../../../assets/icons";
import { getBenefitDescription } from "../utils";
import DateComponent from "./TableComponents/DateComponent";
import CouponBenefitTag from "../../../components/CouponBenefitTag";
import { autoLinkCouponType } from "../NewCoupons/Forms/EligibleUsers";

class ListCoupons extends Component {
  static contextType = FreireContext;

  state = {
    userCompany: null,
    allStations: [],
    allCompanies: [],
    currentCampaign: {},
    loadingTable: false,
    loadingSave: false,
    visibleDrawer: false,
    loadingDrawer: false,
    modalAddCoupon: {
      isVisible: false,
      campaignId: "",
      autoLinkCouponType: "",
    },
    visibleModalDuplicateCampaign: false,
    datePicker: [],
    dateFiltrada: [],
    totalDateFiltrada: 0,
    tablePage: 1,
    companyOptions: [],
    pagination: paginationInitConfig,
    dataExcel: [],
    csvLoading: false,
    companyPartners: [],
  };

  constructor(props) {
    super(props);
    this.CSVLinkRef = React.createRef();
  }

  getAllCompanies = async () => {
    const { data } = await api.get("company");
    const allCompanies = sorter(data, "companyName");
    this.setState({
      allCompanies,
      companyOptions: allCompanies.map((company) => ({
        label: company.companyName,
        value: company._id,
      })),
    });
  };

  getAllStations = async () => {
    const { data } = await api.get(`select/connected_stations`);
    this.setState({ allStations: data });
  };

  async getUserCompany() {
    const company = await getCompanyCurrentUser();
    this.setState({ userCompany: company });
  }

  async listCompanyPartners() {
    try {
      const { data } = await api.get("company/true");
      this.setState({ companyPartners: data });
    } catch (err) {
      console.log("Error fetching companies: ", err);
    }
  }

  componentDidMount() {
    this.getCampaigns();
    this.getUserCompany();
    this.getAllCompanies();
    this.getAllStations();
    this.listCompanyPartners();
  }

  updateCampaign = async (data) => {
    const { freire } = this.context;

    this.setState({ loadingDrawer: true }, async () => {
      try {
        await api.put(`campaign/${this.state.currentCampaign._id}`, data);

        this.setState({ loadingDrawer: false, visibleDrawer: false });

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.CAMPAIGN_EDITED_SUCCESS),
        });

        this.getCampaigns();
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.ERROR_EDIT_CAMPAIGN),
        });
        this.setState({ loadingDrawer: false });
      }
    });
  };

  async deleteCampaign(campaign) {
    const { freire } = this.context;

    this.setState({ loadingTable: true }, async () => {
      try {
        await api.delete(`campaign/${campaign._id}`);

        this.setState({ loadingTable: false });

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.CAMPAIGN_DELETED_SUCCESS),
        });

        this.getCampaigns();
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.ERROR_DELETE_CAMPAIGN),
        });
        this.setState({ loadingTable: false });
      }
    });
  }

  async getCampaigns(params = {}) {
    const { freire } = this.context;

    this.setState({ loadingTable: true }, async () => {
      try {
        const {
          data: { docs, totalDocs },
        } = await api.post("get-campaigns", {
          ...params,
          ...this.state.pagination,
        });

        this.setState({ dateFiltrada: docs, totalDateFiltrada: totalDocs });
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.ERROR_GET_CAMPAIGNS_COUPONS),
        });
      } finally {
        this.setState({ loadingTable: false });
      }
    });
  }

  getParamsFilter = async () => {
    try {
      const params = await this.props.form.validateFields();

      if (!params.name) {
        params.name = undefined;
      }
      if (params.company_ids?.length === this.state.companyOptions.length) {
        params.company_ids = undefined;
      }

      return params;
    } catch (error) {
      return {};
    }
  };

  filter = async (byButton = true) => {
    if (byButton) {
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: 1 },
      }));
    }

    const params = await this.getParamsFilter();
    this.getCampaigns(params);
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.setState({ dateFiltrada: [] });
    this.filter();
  };

  goNew = (state = {}) => {
    this.props.history.push({
      pathname: "/dashboard/new_coupons",
      state,
    });
  };

  getColumns() {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.ACTIVE),
        dataIndex: "enabled",
        key: "enabled",
        width: 66,
        render: (enabled) =>
          typeof enabled === "boolean" ? (
            <Icon
              style={{
                paddingLeft: "25px",
                color: enabled ? "green" : "red",
              }}
              type={enabled ? "check" : "close"}
            />
          ) : (
            "---"
          ),
      },

      {
        title: freire(texts.CAMPAIGN_DATA),
        dataIndex: "name",
        key: "name",
        width: 200,
        render: (name, data) => {
          return (
            <>
              <span>
                <b>{name}</b>
                <br />
              </span>
              <span>{data.company.companyName}</span>
            </>
          );
        },
      },

      {
        title: freire(texts.BENEFIT),
        dataIndex: "benefit",
        key: "benefit",
        width: 170,
        render: (benefit, data) => {
          if (benefit) {
            return (
              <CouponBenefitTag
                benefit={benefit}
                currency={data.currency}
                freire={freire}
              />
            );
          }
          return "---";
        },
      },
      {
        title: freire(texts.LINKED),
        dataIndex: "amountOfCoupons",
        width: 170,
        key: "amountOfCoupons",
        render: (amountOfCoupons, data) => (
          <span>
            <b>{data.amountOfActiveCoupons}</b> de {amountOfCoupons}
          </span>
        ),
      },
      {
        title: freire(texts.USED_BOLD),
        dataIndex: "amountOfCouponUsage",
        width: 170,
        key: "amountOfCouponUsage",
        render: (amountOfCouponUsage, data) => (
          <div className="usesContainer infoContainer">
            <div className="textContainer">
              <span className="usedValue">{amountOfCouponUsage}</span>
            </div>
          </div>
        ),
      },
      {
        title: freire(texts.CREATION_AND_EXPIRATION),
        dataIndex: "createdAt",
        width: 180,
        key: "createdAt",
        render: (createdAt, data) => (
          <DateComponent createdAt={createdAt} data={data} />
        ),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: 170,
        fixed: "right",
        render: (campaign, data) => (
          <>
            <Show when={"edit-cupons"}>
              <Dropdown
                overlay={
                  <Menu>
                    {campaign?.autoLinkCouponType !==
                      autoLinkCouponType.FULL_REGISTER && (
                      <Menu.Item>
                        <Button
                          style={{ border: "none", boxShadow: "none" }}
                          ghost
                          type="primary"
                          onClick={this.handlerVisibleModalAddCoupon(
                            true,
                            campaign.id,
                            campaign?.autoLinkCouponType
                          )}
                        >
                          {freire(texts.ADD_COUPONS)}
                        </Button>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      <Button
                        style={{ border: "none", boxShadow: "none" }}
                        ghost
                        type="primary"
                        onClick={() =>
                          this.setState({
                            visibleDrawer: true,
                            currentCampaign: {
                              ...campaign,
                              expireDate: moment(campaign.expireDate),
                            },
                          })
                        }
                      >
                        {freire(texts.EDIT_CAMPAIGN)}
                      </Button>
                    </Menu.Item>
                    {!campaign?.autoLinkCouponType && (
                      <Menu.Item>
                        <Button
                          style={{ border: "none", boxShadow: "none" }}
                          ghost
                          type="primary"
                          onClick={() => {
                            this.goNew({ campaign });
                          }}
                        >
                          {freire(texts.DUPLICATE_CAMPAIGN)}
                        </Button>
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  shape="round"
                  ghost
                  type="primary"
                  style={{ height: 28, marginBottom: 4 }}
                >
                  <Icon type="more" />
                  {freire(texts.OPTIONS_COUPONS)}
                </Button>
              </Dropdown>
            </Show>

            <br />

            <Show when={"show-cupons-list"}>
              <Tooltip
                placement="top"
                title={freire(texts.SHOW_LISTING_COUPONS)}
              >
                <Link to={`/dashboard/coupons/${campaign.id}`}>
                  <Button
                    shape="round"
                    ghost
                    type="primary"
                    style={{ height: 28 }}
                  >
                    <Icon component={TicketIcon} style={{ fontSize: 15 }} />{" "}
                    {freire(texts.COUPONS)}
                  </Button>
                </Link>
              </Tooltip>
            </Show>
          </>
        ),
      },
    ];
  }

  handlerVisibleModalAddCoupon =
    (visible, campaignId, autoLinkCouponType) => () => {
      this.setState({
        modalAddCoupon: {
          ...this.state.modalAddCoupon,
          isVisible: visible,
          campaignId: visible ? campaignId : "",
          autoLinkCouponType,
        },
      });
    };

  handlerVisibleModalDuplicateCampaign = (visible) => () =>
    this.setState({ visibleModalDuplicateCampaign: visible });

  async downloadExcel() {
    const { freire } = this.context;

    try {
      this.setState({ csvLoading: true });

      const paramsFilter = await this.getParamsFilter();

      const {
        data: { docs },
      } = await api.post("get-campaigns", {
        ...paramsFilter,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      });

      const dataExcel = docs.map((data) => {
        const { text: benefitDescription } = getBenefitDescription(
          data.benefit,
          data.company?.currency,
          freire
        );

        return {
          ...data,
          companyName: data.company?.companyName ?? "---",
          benefit: benefitDescription ?? "---",
          enabled:
            typeof data.enabled === "boolean"
              ? data.enabled
                ? freire(texts.YES)
                : freire(texts.NO)
              : "---",
          createdAt: moment(data.createdAt).format("L LTS"),
          expireDate: moment(data.expireDate).format("L LTS"),
        };
      });

      this.setState({ dataExcel, csvLoading: false }, () => {
        setTimeout(() => {
          if (this.CSVLinkRef.current) {
            this.CSVLinkRef.current.link.click();
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: freire(texts.ERROR_DOWNLOAD_FILE),
            });
          }
        }, 500);
      });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GENERATE_FILE),
      });
    }
  }

  getCsvHeaders = () => {
    const { freire } = this.context;

    return [
      { label: freire(texts.CAMPAIGN), key: "name" },
      { label: freire(texts.COMPANY), key: "companyName" },
      { label: freire(texts.BENEFIT), key: "benefit" },
      { label: freire(texts.QUANTITY), key: "amountOfCoupons" },
      {
        label: freire(texts.NUMBER_ACTIVE_COUPONS),
        key: "amountOfActiveCoupons",
      },
      { label: freire(texts.NUMBER_COUPON_USAGE), key: "amountOfCouponUsage" },
      { label: freire(texts.STATUS_CAMPAIGN), key: "enabled" },
      { label: freire(texts.CREATED_DATE_TIME), key: "createdAt" },
      { label: freire(texts.EXPIRATION), key: "expireDate" },
    ];
  };
  render() {
    const {
      allCompanies,
      allStations,
      visibleDrawer,
      loadingDrawer,
      currentCampaign,
      userCompany,
      modalAddCoupon,
      companyOptions,
      loadingTable,
      dateFiltrada,
      totalDateFiltrada,
      pagination,
      dataExcel,
      csvLoading,
      companyPartners,
    } = this.state;

    const csvHeaders = this.getCsvHeaders();
    const columns = this.getColumns();
    const { freire } = this.context;

    const filterFields = generateFields({
      freire,
      companyOptions,
      onPressButtonNew: () => this.goNew(),
    });

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.COUPONS),
            freire(texts.LISTING_CAMPAIGN_COUPONS),
          ]}
        />

        <GenerateFilter
          form={this.props.form}
          fields={filterFields}
          handleClear={this.clearFilters}
          handleFilter={this.filter}
        />

        <TablePagination
          style={{ backgroundColor: "white" }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          loading={loadingTable}
          rowKey={(data) => data._id}
          dataSource={dateFiltrada}
          columns={columns}
          current={pagination.page}
          total={totalDateFiltrada}
          onPagination={(pagination) =>
            this.setState({ pagination }, () => this.filter(false))
          }
        />

        <EditDrawer
          visible={visibleDrawer}
          loading={loadingDrawer}
          campaign={currentCampaign}
          allCompanies={allCompanies}
          allStations={allStations}
          userCompany={userCompany}
          onClose={() => this.setState({ visibleDrawer: false })}
          onSave={this.updateCampaign}
          companyPartners={companyPartners}
        />

        <ModalAddCoupon
          visible={modalAddCoupon.isVisible}
          campaignId={modalAddCoupon.campaignId}
          codeType={modalAddCoupon?.autoLinkCouponType}
          onClose={this.handlerVisibleModalAddCoupon(false)}
          // onSuccess={this.load}
        />

        <div style={{ paddingBottom: 50, paddingTop: 8 }}>
          <div style={{ display: "none" }}>
            <CSVLink
              ref={this.CSVLinkRef}
              filename={freire(
                texts.CSV_FILE_NAME(moment().format("L HH[h]mm"))
              )}
              className="btn btn-primary"
              headers={csvHeaders}
              data={dataExcel}
            />
          </div>

          {csvLoading ? (
            <span>{freire(texts.LOADING_CSV)}...</span>
          ) : (
            <span
              style={{
                color: "#1890ff",
                cursor: "pointer",
                paddingLeft: 10,
                fontSize: 17,
                border: "1px solid #1890ff",
                borderRadius: "6px",
                padding: "8px 16px",
                display: "inline-block",
              }}
              onClick={() => this.downloadExcel()}
            >
              <span>{freire(texts.DOWNLOAD_CSV)}</span>
              &nbsp;&nbsp;
              <Icon type="download" />
            </span>
          )}
        </div>
      </div>
    );
  }
}
const PageCoupons = Form.create({ name: "filters" })(ListCoupons);
export default withRouter(PageCoupons);
