import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Select,
  Steps,
  Switch,
  notification,
  message,
  Modal,
} from "antd";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import Show from "../../../contexts/PermissionContext/Show";
import withPermission from "../../../contexts/PermissionContext/withPermission";
import { BRAND } from "../../../config";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import { sorter } from "../../../utils/sorter";
import SelectCountry from "../../../components/SelectCountry";
import { COUNTRY_BR } from "../../../constants/countries";
import { validate as isValidEmail } from "email-validator";

import stationReportImg from "../../../assets/img/stationReport.png";
import MonthlyCouponReport from "../../../assets/img/monthlyCouponReport.png";
import quotaReportImg from "../../../assets/img/quota-consumption-alert.png";

const { Step } = Steps;
const { Option } = Select;

const NewCompany = ({ form, history, hasPermission }) => {
  const { freire } = useFreire();

  const [hasIconPack, setHasIconPack] = useState(false);
  const [iconPacks, setIconPacks] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [emails, setEmails] = useState([]);

  const [loadingFetchData, setLoadingFetchData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [isVisibleParentCompany, setIsVisibleParentCompany] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);

  const [loading] = useState(false);

  const getIconPacks = useCallback(async () => {
    try {
      const { data: iconPacks } = await api.get("stations/icon-packs");

      return iconPacks;
    } catch (error) {
      throw new Error(freire(texts.ERROR_LOAD_PACK_ICONS));
    }
  }, [freire]);

  const getCompanies = useCallback(async () => {
    try {
      const { data: companies } = await api.get("company");

      const companiesHolding = sorter(
        companies.filter((company) => company.holding),
        "companyName"
      );

      return companiesHolding;
    } catch (error) {
      throw new Error(freire(texts.ERROR_LIST_COMPANIES));
    }
  }, [freire]);

  const fetchData = useCallback(async () => {
    try {
      setLoadingFetchData(true);

      const [iconPacks, companies] = await Promise.all([
        getIconPacks(),
        getCompanies(),
      ]);

      setIconPacks(iconPacks);
      setCompanies(companies);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: error.message,
      });
    } finally {
      setLoadingFetchData(false);
    }
  }, [freire, getCompanies, getIconPacks]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const submit = async () => {
    form.validateFields(async (err, values) => {
      if (err) return;

      values.iconPack = values.iconPack ?? null;

      try {
        setLoadingSave(true);
        values.emails = emails;

        await api.post("company", values);

        setLoadingSave(false);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.COMPANY_ADD_SUCCESS),
        });

        history.push("/dashboard/companys");
      } catch (error) {
        setLoadingSave(false);

        notification.error({
          message: freire(texts.WEN_WONG),
          description: error?.response?.data?.message || error.message,
        });
      }
    });
  };

  const { getFieldDecorator } = form;
  const parentCompanyID = form.getFieldValue("parentCompanyID");
  const canEditParentCompany = hasPermission("edit-parent-company");

  const parentCompanyCountry = useMemo(
    () =>
      companies?.find((company) => company._id === parentCompanyID)?.country ??
      COUNTRY_BR,
    [companies, parentCompanyID]
  );

  const showModal1 = () => {
    setIsModalVisible1(true);
  };

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const showModal3 = () => {
    setIsModalVisible3(true);
  };

  return (
    <div className="container">
      <div className="content">
        <Row>
          <Col span={4}>
            <Steps current={1} size="small" direction="vertical">
              <Step
                icon={<Icon type="profile" />}
                title={freire(texts.COMPANIES)}
                description={freire(texts.ADD_COMPANY)}
              />
            </Steps>
          </Col>

          <Col span={20}>
            <Form wrapperCol={{ span: 24 }}>
              <span className="titleNewCompanyEdt">{freire(texts.INFO)}</span>
              <Row gutter={[16, 0]}>
                <Col span={18}>
                  <Form.Item label={freire(texts.NAME)}>
                    {getFieldDecorator("companyName", {
                      rules: [
                        {
                          required: true,
                          message: freire(texts.REQUIRED),
                        },
                      ],
                    })(<Input placeholder={freire(texts.NAME)} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={8}>
                  <Form.Item label={freire(texts.SELECT_DOCUMENT_TYPE)}>
                    {getFieldDecorator("document_type", {})(
                      <Select placeholder={freire(texts.SELECT_DOCUMENT_TYPE)}>
                        {["CNPJ", "CUIT"].map((value) => (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label={freire(texts.COMPANY_DOCUMENT)}>
                    {getFieldDecorator("document", {})(<Input/>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={18}>
                  <Form.Item label={freire(texts.PIPEDRIVE_ID)}>
                    {getFieldDecorator("pipedrive_id", {})(
                      <Input/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item label={freire(texts.IS_PARENT_COMPANY)}>
                  {getFieldDecorator("isParentCompany", {
                    valuePropName: "checked",
                    initialValue: false,
                  })(
                    <Switch
                      onChange={(value) => setIsVisibleParentCompany(value)}
                    ></Switch>
                  )}
                </Form.Item>
              </Row>
              {canEditParentCompany && !isVisibleParentCompany && (
                <Row gutter={[16, 0]}>
                  <Col span={18}>
                    <Form.Item label={freire(texts.PARENT_COMPANY)}>
                      {getFieldDecorator("parentCompanyID", {
                        initialValue: BRAND,
                      })(
                        <Select
                          placeholder={freire(texts.SELECT_PARENT_COMPANY)}
                          disabled={loadingFetchData || loadingSave}
                          loading={loadingFetchData}
                          showSearch
                          optionFilterProp="children"
                        >
                          {companies.map((company) => (
                            <Option key={company._id} value={company._id}>
                              {company.companyName}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gutter={[16, 0]}>
                <Col span={18}>
                  <SelectCountry
                    form={form}
                    initialValue={parentCompanyCountry}
                    disabled={loadingFetchData || loadingSave}
                  />
                </Col>
              </Row>

              <Show when={"edit-company-pin"}>
                <span className="titleNewCompanyEdt">
                  {freire(texts.CUSTOMIZATION)}
                </span>
                <Row gutter={[16, 0]}>
                  <Col span={5}>
                    <Form.Item label={freire(texts.PINS_CUSTOMIZED)}>
                      <Switch
                        checkedChildren={freire(texts.YES)}
                        unCheckedChildren={freire(texts.NO)}
                        onChange={setHasIconPack}
                        checked={hasIconPack}
                        disabled={loadingFetchData || iconPacks.length === 0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={13}>
                    <Form.Item label={freire(texts.CHOOSE_PIN)}>
                      {hasIconPack ? (
                        getFieldDecorator("iconPack", {
                          rules: [
                            {
                              required: true,
                              message: freire(texts.REQUIRED),
                            },
                          ],
                        })(
                          <Select
                            allowClear
                            loading={loadingFetchData}
                            disabled={
                              loadingFetchData || iconPacks.length === 0
                            }
                            placeholder={freire(texts.PATTERN)}
                            showSearch
                          >
                            {iconPacks.map((iconPack) => (
                              <Select.Option
                                key={iconPack.alias}
                                value={iconPack.alias}
                              >
                                {iconPack.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )
                      ) : (
                        <Select
                          loading={loadingFetchData}
                          disabled
                          placeholder={freire(texts.PATTERN)}
                          value={undefined}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={18}>
                    <Form.Item label={freire(texts.URL_POWERBI)}>
                      {getFieldDecorator("powerbi", {
                        rules: [
                          {
                            required: false,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder={freire(texts.URL_POWERBI)} />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Show>

              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <p className="titleNewCompanyEdt">{freire(texts.REPORT)}</p>
                  <Col span={24}>
                    <Form.Item label={freire(texts.EMAIL_LIST)}>
                      <p className="titleForSelectModal">
                        Os e-mails nessa lista receberão relatórios mensais com
                        atualizações das estações
                        <span
                          style={{ cursor: "pointer", margin: "3px" }}
                          onClick={showModal1}
                        >
                          <strong>(exemplo)</strong>
                        </span>
                        <Modal
                          visible={isModalVisible1}
                          onCancel={() => setIsModalVisible1(false)}
                          footer={null}
                          maskClosable={true}
                        >
                          <img src={stationReportImg} alt="Station Report" />
                        </Modal>
                        - consumo de kWh, número de usuários, quantidade e tempo
                        médio de cargas - e a utilização dos cupons
                        <span
                          style={{ cursor: "pointer", margin: "3px" }}
                          onClick={showModal2}
                        >
                          <strong>(exemplo)</strong>
                        </span>
                        <Modal
                          visible={isModalVisible2}
                          onCancel={() => setIsModalVisible2(false)}
                          footer={null}
                          maskClosable={true}
                        >
                          <img src={MonthlyCouponReport} alt="Monthly Coupon" />
                        </Modal>
                        incluindo alertas de consumo da cota
                        <span
                          style={{ cursor: "pointer", margin: "3px" }}
                          onClick={showModal3}
                        >
                          <strong>(exemplo)</strong>
                        </span>
                        <Modal
                          visible={isModalVisible3}
                          onCancel={() => setIsModalVisible3(false)}
                          footer={null}
                          maskClosable={true}
                        >
                          <img src={quotaReportImg} alt="Quota Report" />
                        </Modal>
                      </p>
                      {getFieldDecorator("emails")(
                        <Select
                          mode="tags"
                          disabled={loading}
                          style={{ width: "75%" }}
                          placeholder={freire(texts.INPUT_EMAILS)}
                          onSelect={(value) => {
                            if (!isValidEmail(value)) {
                              message.error(freire(texts.INFORM_VALID_EMAIL));
                              const newEmails = [...emails];
                              const index = newEmails.indexOf(value);
                              if (index > -1) {
                                newEmails.splice(index, 1);
                                setEmails(newEmails);
                              }
                            } else {
                              setEmails([...emails, value]);
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>

              <div className="footer">
                <Button loading={loadingSave} onClick={submit} type="primary">
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const PageNewCompany = Form.create({ name: "inputs" })(NewCompany);

export default withPermission(withRouter(PageNewCompany));
