export const BRAND =
  process.env.REACT_APP_TUPI_COMPANY_ID ?? "5fcf83cb29d326670e91bf90";

export const NEO = "600197c5f950233332be931b";
export const NEOCHARGE = "627573bdfa4c1c18db6395f6";
export const MELI_MOOCA = "61562d6855fe750ac2fec423";

export const EFFIX = "610172319e61c5679e296aa7";

export const SHELL =
  process.env.REACT_APP_SHELL_COMPANY_ID ?? "6276c8ea2794d940d2cd2426";

export const MOBILIZE = "627437b7fa4c1c18db453cd7";

export const BYD = "66217edeedb2c20009cb244d";
export const BYD_TEST = "65d8ef81d524e60008380924";
