import React, { Fragment, memo, useMemo } from "react";
import { Col, Form, Select } from "antd";
import * as texts from "../locales";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { useFreire } from "../../../utils/freireContext";
import { sorter } from "../../../utils/sorter";
import { useOnUpdate } from "../../../hooks";

const { Option } = Select;

interface IBrasilSelectLocation {
  form: WrappedFormUtils<any>;
  disabled: boolean;
  loadingStates: boolean;
  loadingCities: boolean;
  states: any[];
  cities: any[];
  onStateChange: (stateId: string) => void;
}

const BrasilSelectLocation: React.FC<IBrasilSelectLocation> = ({
  form,
  disabled,
  loadingStates,
  loadingCities,
  states,
  cities,
  onStateChange,
}) => {
  const { freire } = useFreire();

  const selectedState = form.getFieldValue("address2.state");

  useOnUpdate(() => {
    if (selectedState) {
      onStateChange(selectedState);
    }
  }, [selectedState]);

  console.log("state", selectedState)

  const onSelectState = () => form.resetFields(["address2.city"]);

  const statesSorted: any[] = useMemo(() => sorter(states, "nome"), [states]);
  const citiesSorted: any[] = useMemo(() => sorter(cities, "nome"), [cities]);

  return (
    <Fragment>
      <Col span={12}>
        <Form.Item label={freire(texts.STATE)}>
          {form.getFieldDecorator("address2.state")(
            <Select
              showSearch
              placeholder={
                loadingStates
                  ? freire(texts.LOADING)
                  : freire(texts.SELECT_STATE)
              }
              onSelect={onSelectState}
              loading={loadingStates}
              disabled={loadingStates || disabled}
            >
              {statesSorted.map((state) => (
                <Option key={`${state.id}-${state.nome}`} value={state.sigla}>
                  {state.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={freire(texts.CITY)}>
          {form.getFieldDecorator("address2.city")(
            <Select
              showSearch
              placeholder={
                loadingCities
                  ? freire(texts.LOADING)
                  : freire(texts.SELECT_CITY)
              }
              loading={loadingCities}
              disabled={!selectedState || loadingCities || disabled}
            >
              {citiesSorted.map((city) => (
                <Option key={city.id} value={city.nome}>
                  {city.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export default memo(BrasilSelectLocation);
