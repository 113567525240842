import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Drawer,
  Switch,
  Select,
  message,
  Radio,
  notification,
  Divider,
  Icon,
  Alert,
  Popover,
  InputNumber,
  DatePicker,
} from "antd";
import "./index.css";
import api from "../../services/api";
import imgController from "../../assets/img/controller.png";
import moment from "moment-timezone";

import { withRouter } from "react-router-dom";
import { Show } from "../../contexts/PermissionContext";
import { FreireContext } from "../../utils/freireContext";
import * as texts from "./locales";
import TitleTopicEditDrawer from "../TitleTopicEditDrawer";
import RadioGroup from "../TupiFielsForms/RadioGroup";
import TupiSelect from "../TupiSelect";
import EnergyRemunerationTable from "./EnergyRemunerationTable";
import RemunerationMediaTable from "./RemunerationMediaTable";
import SelectLocation from "../SelectLocation";
import SelectCountry from "../SelectCountry";
import { formatters } from "../../utils/formatters";
import { POWER_AC, POWER_DC } from "../../constants/power";
import { sorter } from "../../utils/sorter";
import BusinessHour from "../BusinessHour";
import TextToolTip from "../TextToolTip/index";
import { formatarMoeda } from "../FormatingUtils";
import {
  IdleChargePeriodDefault,
  WayToChargeIdleFee,
} from "../../constants/station";
import { COUNTRY_BR } from "../../constants/countries";
import { isPartner as checkIsPartner } from "../../utils/isPartner";

const { Option } = Select;

// TODO: GET /contract-models
// type ContractModel = {
//   alias: string;
//   name: string;
//   fullName?: string;
//   zendeskTag: string;
// };
export const contractModelOptions = (freire) => [
  {
    label: freire(texts.CAAS_CONTRACT_MODEL),
    value: "caas",
  },
  {
    label: freire(texts.EAAS_CONTRACT_MODEL),
    value: "eaas",
  },
  {
    label: freire(texts.SAAS_CONTRACT_MODEL),
    value: "saas",
  },
];

const genericOptions = (freire) => [
  {
    label: freire(texts.NO_INFO),
    value: null,
  },
  { label: freire(texts.YES), value: true },
  { label: freire(texts.NO), value: false },
];

class EditDrawer extends Component {
  static contextType = FreireContext;

  fields = [
    "name",
    "freeParking",
    "address",
    "lat",
    "lng",
    "phone",
    "disabled",
    "companyID",
    "private",
    "acceptCoupon",
    "payment",
    "paymentCharge",
    "plugs",
    "users",
    "stationFlags",
    "stationParkingSpots",
    "stationPartners",
    "businessHoursPeriod",
    "idleFee",
    "adapters",
  ];
  state = {
    plugs: [],
    connectedPlugs: [],
    plugsSelecteds: [],
    listLocals: [],
    listTypes: [],
    listModels: [],
    locations: [],
    remunerationMedia: undefined,
    remunerationEnergy: undefined,
    plugsConcurrently: undefined,
    isPowerDividedToPlugs: undefined,
    tupiInstall: undefined,
    splitRemunerationMedia: [],
    hasPartnership: false,
    isBusinessHourSaved: true,
    saveTry: false,
    hasAdapters: false,
    companyPartners: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        const {
          connectedDate,
          installedAt,
          operationStartedAt,
          contractStartedAt,
          contractEndedAt,
          firstPaymentAt,
          splitRemunerationMedia,
          contractModel,
          idleFee,
          ...rest
        } = this.props.station;

        this.listCompanyPartners();
        this.getLocations();
        this.getPlugs();
        this.getStationLocals();
        this.getStationModels();
        this.getStationTypes();
        this.props.form.resetFields();
        this.props.form.setFieldsValue({
          ...rest,
          contractModel: contractModel === null ? undefined : contractModel,
          connectedDate: connectedDate === null ? null : moment(connectedDate),
          installedAt: installedAt === null ? null : moment(installedAt),
          operationStartedAt:
            operationStartedAt === null ? null : moment(operationStartedAt),
          contractStartedAt:
            contractStartedAt === null ? null : moment(contractStartedAt),
          contractEndedAt:
            contractEndedAt === null ? null : moment(contractEndedAt),
          firstPaymentAt:
            firstPaymentAt === null ? null : moment(firstPaymentAt),
          idleFee: {
            ...idleFee,
            gracePeriod: moment.duration(idleFee?.gracePeriod).asMinutes(),
          },
        });

        this.setState({
          plugsSelecteds: this.props.station.plugs,
          connectedPlugs: this.props.station.connectedPlugs,
          remunerationMedia: this.props.station.remunerationMedia,
          remunerationEnergy: this.props.station.remunerationEnergy,
          plugsConcurrently: this.props.station.plugsConcurrently,
          isPowerDividedToPlugs: this.props.station.isPowerDividedToPlugs,
          tupiInstall: this.props.station.tupiInstall,
          splitRemunerationMedia: this.props.station.splitRemunerationMedia,
          hasPartnership:
            !!this.props.station?.stationPartners &&
            this.props.station?.stationPartners.length > 0,
          hasAdapters: this.props.station?.adapters?.length > 0,
        });

        this.getStationPartners();
      } else {
        this.setState({
          plugsSelecteds: [],
          connectedPlugs: [],
        });
      }
    }
  }

  async getStationLocals() {
    const { freire } = this.context;
    try {
      const response = await api.get(`station-locals`);
      this.setState({ listLocals: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_LOCATIONS),
      });
    }
  }

  async getStationModels() {
    const { freire } = this.context;
    try {
      const response = await api.get(`station-models`);
      this.setState({ listModels: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_MODELS),
      });
    }
  }

  async getStationTypes() {
    const { freire } = this.context;

    try {
      const response = await api.get(`station-types`);
      this.setState({ listTypes: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_TYPES),
      });
    }
  }

  async getLocations() {
    const { freire } = this.context;
    try {
      const response = await api.get(`locations`);
      this.setState({ locations: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_LOCATIONS),
      });
    }
  }

  async getPlugs(params = {}) {
    const { freire } = this.context;

    this.setState({ loadingPlugs: true, plugs: [] }, async () => {
      try {
        const { data: plugs } = await api.get(`plugs`, { params });

        this.setState({ plugs });
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.ERROR_GET_PLUGS),
        });
      }
    });
  }

  async listCompanyPartners() {
    try {
      const { data } = await api.get("company/true");
      this.setState({ companyPartners: data });
    } catch (err) {
      console.log("Error fetching companies: ", err);
    }
  }

  clearWarningsStates() {
    this.setState({ saveTry: false, isBusinessHourSaved: true });
  }

  save() {
    const { freire } = this.context;

    if (!this.state.saveTry) {
      if (!this.state.isBusinessHourSaved) {
        message.warning(freire(texts.WARNING_BUSINESS_HOUR));
        this.setState({ saveTry: true });
        return;
      }
    } else {
      message.warning(freire(texts.WARNING_BUSINESS_HOUR_NOT_SAVED));
      this.clearWarningsStates();
    }
    this.props.form.validateFieldsAndScroll(
      { scroll: { offsetTop: 75 } },
      async (err, station) => {
        if (!this.state.hasPartnership) {
          station.stationPartners = [];
        }
        if (err) {
          return;
        }

        const { connectedPlugs, splitRemunerationMedia } = this.state;
        var { payment, paymentCharge, idleFee } = station;

        if (payment) {
          station.payment.value = Number(
            station.payment.value.replace(/[.,]/g, "")
          );

          if (payment.enabled && station.payment.value === 0) {
            message.warning(freire(texts.ERROR_INFO_RESERVE_PAYMENT));
            return;
          }
        }

        if (paymentCharge) {
          if (paymentCharge.enabled && paymentCharge.method === "") {
            message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT_METHOD));
            return;
          }

          station.paymentCharge.value = Number(
            station.paymentCharge.value.replace(/[.,]/g, "")
          );

          if (paymentCharge.enabled && station.paymentCharge.value === 0) {
            message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT));
            return;
          }

          if (
            paymentCharge?.method === "Time" &&
            (!paymentCharge.period ||
              !parseInt(paymentCharge.perTime) > 0 ||
              !paymentCharge.timeWindow)
          ) {
            message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT_METHOD));
            return;
          }
        }

        if (connectedPlugs) {
          station.connectedPlugs = connectedPlugs;
        }

        station.disabled = !station.disabled;
        station.connectedDate = station.connectedDate?.toISOString();
        station.splitRemunerationMedia = splitRemunerationMedia;
        if (!station.partnerInstallerName) station.partnerInstallerName = null;
        if (!station.contractModel) station.contractModel = null;

        if (idleFee?.enabled) {
          if (idleFee.value === "0,00" || idleFee.gracePeriod < 1) {
            message.warning(freire(texts.ERROR_INFO_IDLE_FEE));
            return;
          }
          //TODO: desmockar wayToCharge e chargePeriod
          station.idleFee = {
            enabled: idleFee.enabled,
            value: Number(idleFee.value.replace(",", "")),
            gracePeriod: moment.duration(idleFee.gracePeriod, "minutes"),
            wayToCharge: WayToChargeIdleFee.PER_DURATION,
            chargePeriod: IdleChargePeriodDefault,
          };
        } else {
          station.idleFee = {
            enabled: false,
            value: 0,
            gracePeriod: "",
            chargePeriod: "",
            wayToCharge: WayToChargeIdleFee.NONE,
          };
        }

        this.props.onSave(station);
      }
    );
  }

  handlePlugs = (value, e) => {
    this.setState({
      plugsSelecteds: value,
    });
  };

  handleAdaptersSwitch = (hasAdapters) => {
    this.setState({ hasAdapters });

    if (!hasAdapters) {
      this.props.form.setFieldsValue({ adapters: [] });
    }
  };

  addPlug() {
    var { connectedPlugs } = this.state;
    var newConnectedPlug = {
      plug_id: null,
      station_ID: this.props.station._id,
      current: null,
      power: null,
    };
    var newConnectedPlugs = connectedPlugs.concat(newConnectedPlug);

    this.setState({ connectedPlugs: newConnectedPlugs });
  }

  removePlugs() {
    this.setState({ connectedPlugs: [] });
  }

  changerPlug(plug, index) {
    var { connectedPlugs } = this.state;
    connectedPlugs[index].plug_id = plug;

    this.setState({ connectedPlugs });
  }

  getSummary = () => {
    const { paymentCharge } = this.props.form.getFieldsValue();
    const { freire } = this.context;

    if (
      paymentCharge &&
      paymentCharge.enabled &&
      paymentCharge.period !== "" &&
      paymentCharge.value &&
      paymentCharge.perTime > 0 &&
      paymentCharge.timeWindow !== ""
    ) {
      const timeWindow =
        paymentCharge.timeWindow === "min"
          ? freire(texts.METHOD_TIME_MIN)
          : freire(texts.METHOD_TIME_HOUR);
      if (paymentCharge.period === "relative") {
        return freire(
          texts.METHOD_RELATIVE_DESCRIPTION(
            paymentCharge.value,
            paymentCharge.perTime,
            timeWindow
          )
        );
      } else if (paymentCharge.period === "fixed") {
        return freire(
          texts.METHOD_FIXED_DESCRIPTION(
            paymentCharge.value,
            paymentCharge.perTime,
            timeWindow
          )
        );
      }
    }

    return freire(texts.PAYMENT_DESCRIPTION_INCOMPLETE);
  };
  setIsBusinessHourSaved(value) {
    this.setState({ isBusinessHourSaved: value });
  }

  getStationPartners() {
    const partners =
      !!this.props.station?.stationPartners &&
      this.props.station?.stationPartners?.length > 0
        ? this.props.station?.stationPartners.map((partner) => partner.id)
        : [];
    this.props.form.setFieldsValue({ stationPartners: partners });
  }

  onChangeLocation(id) {
    const location = this.state.locations.find(
      (location) => location.id === id
    );

    this.props.form.setFieldsValue({
      name: location.name,
      country: location.country,
      lat: location.coordinates.latitude,
      lng: location.coordinates.longitude,
      address: location.address,
      phone: location.phone,
      companyID: location.company_id,
      private: location.private,
      freeParking: location.freeParking,
    });

    if (location.country === COUNTRY_BR) {
      this.props.form.setFieldsValue({
        address2: {
          state: location.state,
          city: location.city,
        },
      });
    } else {
      this.props.form.setFieldsValue({
        address2: {
          province: location.state,
          city: location.city,
        },
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { visible, onClose, loading, companies } = this.props;
    const {
      plugs,
      plugsSelecteds,
      connectedPlugs,
      listLocals,
      listModels,
      listTypes,
      remunerationMedia,
      remunerationEnergy,
      tupiInstall,
      splitRemunerationMedia,
      locations,
      hasAdapters,
      companyPartners,
    } = this.state;
    const { payment, paymentCharge, current, canReserve, companyID, idleFee } =
      this.props.form.getFieldsValue();
    const { freire } = this.context;
    const loadingPaymentChargeValue =
      loading ||
      (paymentCharge &&
        (!paymentCharge.enabled || paymentCharge.method === ""));

    const selectedCountry =
      this.props.form.getFieldValue("country") ??
      this.props.station?.country ??
      COUNTRY_BR;

    const selectedCompany = companies.find(
      (company) => company._id === companyID
    );

    const isPartner = checkIsPartner(
      this.props.station?.companyID,
      this.props.station?.parentCompanyID,
      this.props.companyID
    );

    const selectedCompanyCurrency = formatters.currency(
      freire.userLanguage,
      selectedCompany?.currency
    )(null);

    const stationFlags = ["Shell", "Tupinambá"];

    const formList = connectedPlugs?.map((item, index) => (
      <div style={{ width: "90%", marginLeft: "5%" }} key={index.toString()}>
        <span
          style={{
            color: "#7A84BF",
            fontWeight: "bold",
            marginBottom: 8,
          }}
        >
          {freire(texts.POWER_PLUG(index + 1))}
        </span>
        <Row gutter={16}>
          <Col span={12} style={{ height: 75 }}>
            <div
              style={{
                color: "rgb(0,0,0)",
                fontSize: 14,
                fontWeight: "bold",
                paddingBottom: 8,
              }}
            >
              {freire(texts.PLUG)}
            </div>
            <Select
              disabled={loading || isPartner}
              defaultValue={item.plug_id}
              onChange={(e) => this.changerPlug(e, index)}
            >
              {plugsSelecteds &&
                plugs
                  .filter((item) => plugsSelecteds.includes(item.name))
                  .map((plug) => (
                    <Option key={plug._id} value={plug._id}>
                      {plug.name}
                    </Option>
                  ))}
            </Select>
          </Col>
          <Col span={6} style={{ height: 75 }}>
            <div
              style={{
                color: "rgb(0,0,0)",
                fontSize: 14,
                fontWeight: "bold",
                paddingBottom: 8,
              }}
            >
              {freire(texts.CURRENT)}
            </div>
            <Radio.Group
              disabled={loading || isPartner}
              defaultValue={connectedPlugs[index].current}
              onChange={(e) => {
                connectedPlugs[index].current = e.target.value;
                connectedPlugs[index].power = null;
                this.setState({ connectedPlugs });
              }}
              buttonStyle="solid"
            >
              <Radio.Button value="AC">AC</Radio.Button>
              <Radio.Button value="DC">DC</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={6} style={{ height: 75 }}>
            <div
              style={{
                color: "rgb(0,0,0)",
                fontSize: 14,
                fontWeight: "bold",
                paddingBottom: 8,
              }}
            >
              {connectedPlugs[index].current
                ? freire(texts.POWER(connectedPlugs[index].current))
                : freire(texts.POWER(""))}
            </div>
            <Select
              disabled={
                (connectedPlugs[index] && !connectedPlugs[index].current) ||
                loading ||
                isPartner
              }
              placeholder={freire(texts.SELECT_POWER)}
              defaultValue={connectedPlugs[index].power}
              onChange={(e) => {
                connectedPlugs[index].power = e;
                this.setState({ connectedPlugs });
              }}
            >
              {(connectedPlugs[index] && connectedPlugs[index].current === "AC"
                ? POWER_AC
                : POWER_DC
              ).map((power) => (
                <Option key={power} value={power}>
                  {`${power.toLocaleString(freire.userLanguage)} kW`}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Divider
          style={{
            marginTop: 0,
          }}
        />
      </div>
    ));
    return (
      <Drawer
        title={freire(texts.STATION_EDIT)}
        width={"50%"}
        onClose={() => (loading ? {} : onClose())}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        <Form id="form_edit_station" layout="vertical">
          <TitleTopicEditDrawer title={freire(texts.INFORMATION)} />
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col span={24}>
              <Form.Item label={freire(texts.NAME)}>
                {getFieldDecorator("name")(
                  <Input
                    disabled={isPartner}
                    placeholder={freire(texts.NAME)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {/* TODO: expandir para 24 caso o user não tenha a permissão de editar a company (edit-company-station) */}
            <Col span={12}>
              <Form.Item label={freire(texts.LOCALIZATION)}>
                {getFieldDecorator("location_id")(
                  <Select
                    disabled={isPartner}
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    onChange={(id) => this.onChangeLocation(id)}
                  >
                    {locations &&
                      sorter(locations).map((location) => (
                        <Option
                          key={location.id}
                          value={location.id}
                          label={location.name}
                        >
                          {location.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Show when={"edit-company-station"}>
              <Col span={12}>
                <Form.Item
                  label={freire(texts.COMPANY)}
                  extra={freire(texts.SELECT_COMPANY_WARN)}
                >
                  {getFieldDecorator("companyID")(
                    <Select
                      disabled
                      showSearch
                      optionFilterProp="children"
                      placeholder={freire(texts.SELECT_COMPANY)}
                    >
                      {companies.map((company) => (
                        <Option
                          disabled={company.country !== selectedCountry}
                          key={company._id}
                          value={company._id}
                        >
                          {company.companyName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Show>
          </Row>
          <Row gutter={16} style={{ display: "flex", alignItems: "center" }}>
            <Col span={12}>
              {!!companyID && (
                <Row>
                  <Switch
                    disabled={loading || isPartner}
                    checked={this.state.hasPartnership}
                    onChange={(value) => {
                      this.setState({ hasPartnership: value });
                    }}
                  />
                  <span style={{ marginLeft: 12 }}>
                    {freire(texts.HAS_PARTNERSHIP)}
                  </span>
                </Row>
              )}
            </Col>
            <Col span={12}>
              {this.state.hasPartnership && (
                <Form.Item label={freire(texts.STATION_PARTNERSHIPS)}>
                  {getFieldDecorator("stationPartners", {
                    initialValue:
                      !!this.props.station?.stationPartners &&
                      this.props.station?.stationPartners?.length > 0
                        ? this.props.station?.stationPartners.map(
                            (partner) => partner.id
                          )
                        : [],
                  })(
                    <Select
                      allowClear
                      disabled={loading || isPartner}
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                    >
                      {companyPartners?.map((company) => (
                        <Option
                          disabled={company.country !== selectedCountry}
                          key={company._id}
                          value={company._id}
                        >
                          {company.companyName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Form.Item label="">
                {getFieldDecorator("disabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || isPartner}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {getFieldValue("disabled")
                    ? freire(texts.STATION_ACTIVE)
                    : freire(texts.STATION_DISABLED)}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <SelectCountry
                form={this.props.form}
                label={texts.SELECT_COUNTRY}
                disabled={true}
                initialValue={selectedCountry}
                showWarn={false}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col>
              <Form.Item label={freire(texts.ADDRESS)}>
                {getFieldDecorator("address")(
                  <Input disabled placeholder={freire(texts.ADDRESS)} />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <SelectLocation
              disabled={true}
              form={this.props.form}
              countryCode={selectedCountry}
              currentLocation={this.props.station?.address2}
            />
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={freire(texts.LATITUDE)}>
                {getFieldDecorator("lat")(
                  <InputNumber
                    disabled={loading || isPartner}
                    style={{ width: "100%" }}
                    placeholder="Ex.: -00.0000"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={freire(texts.LONGITUDE)}>
                {getFieldDecorator("lng")(
                  <InputNumber
                    disabled={loading || isPartner}
                    style={{ width: "100%" }}
                    placeholder="Ex.: -00.0000"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item label="">
                {getFieldDecorator("private", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.PRIVATE_STATION)}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: 0 }} />
          <TitleTopicEditDrawer title={freire(texts.OPERATION)} />
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={freire(texts.PLUGS)}>
                {getFieldDecorator("plugs")(
                  <Select
                    mode="multiple"
                    disabled={loading || isPartner}
                    onChange={this.handlePlugs}
                  >
                    {plugs &&
                      sorter(plugs).map((plug) => (
                        <Option key={plug._id} value={plug.name}>
                          {plug.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={freire(texts.CURRENT)}>
                {getFieldDecorator("current")(
                  <Radio.Group
                    disabled={loading || isPartner}
                    onChange={() => this.props.form.resetFields(["power"])}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="AC">AC</Radio.Button>
                    <Radio.Button value="DC">DC</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={freire(texts.POWER(current))}>
                {getFieldDecorator("power")(
                  <Select
                    disabled={!current || loading || isPartner}
                    placeholder={freire(texts.SELECT_POWER)}
                  >
                    {(current === "AC" ? POWER_AC : POWER_DC).map((power) => (
                      <Option key={power} value={power}>
                        {`${power.toLocaleString(freire.userLanguage)} kW`}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {formList}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <Button
                disabled={isPartner}
                style={{
                  color: "#7A84BF",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  borderWidth: 0,
                }}
                onClick={() => this.addPlug()}
              >
                {freire(texts.ADD_POWER_PLUG)}
              </Button>
            </div>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <Button
                disabled={isPartner}
                style={{
                  color: "#FC385C",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  borderWidth: 0,
                }}
                onClick={() => this.removePlugs()}
              >
                {freire(texts.REMOVE_ALL_POWER_PLUGS)}
              </Button>
            </div>
          </div>

          <Row
            gutter={16}
            style={{
              display: "flex",
              alignItems: "flex-end",
              margin: "26px 28px 0",
            }}
          >
            <Col span={12}>
              <Form.Item label="">
                <Switch
                  disabled={loading || isPartner}
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                  checked={hasAdapters}
                  onChange={this.handleAdaptersSwitch}
                />
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ADD_ADAPTERS)}
                </span>
              </Form.Item>
            </Col>

            <Col span={12} style={!hasAdapters && { display: "none" }}>
              <Form.Item label={freire(texts.ADAPTERS)}>
                {getFieldDecorator("adapters", {
                  rules: [
                    {
                      required: hasAdapters,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    disabled={loading}
                    onChange={this.handleAdapters}
                  >
                    {sorter(plugs).map((plug) => (
                      <Option key={plug._id} value={plug.name}>
                        {plug.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ marginTop: 0, marginBottom: 24 }} />

          <div style={{ fontWeight: 700, marginTop: 12 }}>
            {freire(texts.BUSINESSHOURS)}
          </div>
          <div>{freire(texts.BUSINESSHOURS_INFO)}</div>
          <Col style={{ marginLeft: 64, marginRight: 16, marginTop: 14 }}>
            <Form.Item label={freire(texts.OLD_BUSINESS_HOUR)}>
              {getFieldDecorator("businessHours")(
                <Input
                  disabled={loading || isPartner}
                  placeholder={freire(texts.OLD_BUSINESS_HOUR_PLACEHOLDER)}
                />
              )}
            </Form.Item>
            <div
              style={{
                border: "1px solid #1890FF",
                borderRadius: 5,
                backgroundColor: "#E6F7FF",
                padding: 8,
                fontSize: 14,
              }}
            >
              <Icon
                type="warning"
                style={{ color: "#1890FF", marginRight: 8 }}
              />
              {freire(texts.OLD_BUSINESS_HOUR_WARNING)}
            </div>
          </Col>
          <Form.Item>
            {getFieldDecorator("businessHoursPeriod")(
              <BusinessHour
                disabled={isPartner}
                loading={loading}
                form={this.props.form}
                station={this.props.station}
                setIsSaved={this.setIsBusinessHourSaved.bind(this)}
                isSaved={this.state.isBusinessHourSaved}
              />
            )}
          </Form.Item>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col span={12}>
              <Form.Item label={freire(texts.PHONE)}>
                {getFieldDecorator("phone")(
                  <Input disabled placeholder={freire(texts.PHONE)} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="">
                {getFieldDecorator("freeParking", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.FREE_PARKING)}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Show when={"edit-visibility-station"}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={freire(texts.PLUGS_CONCURRENTLY)}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ whiteSpace: "pre-wrap", marginBottom: 16 }}>
                      {freire(texts.TEXT_CONNECTORS)}
                    </span>
                    {getFieldDecorator("plugsConcurrently")(
                      <Radio.Group
                        disabled={loading || isPartner}
                        onChange={(e) => {
                          this.setState({
                            plugsConcurrently: e.target.value,
                          });
                          this.props.form.resetFields([
                            "isPowerDividedToPlugs",
                          ]);
                        }}
                        buttonStyle="solid"
                      >
                        <Radio.Button value={null}>
                          {freire(texts.NO_INFORMATIONS)}
                        </Radio.Button>
                        <Radio.Button value={true}>
                          {freire(texts.YES)}
                        </Radio.Button>
                        <Radio.Button value={false}>
                          {freire(texts.NO)}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                display: getFieldValue("plugsConcurrently") ? "block" : "none",
              }}
            >
              <Col span={12}>
                <Form.Item label={freire(texts.SIMULTANEOUS_POWER)}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ whiteSpace: "nowrap", marginBottom: 16 }}>
                      {freire(texts.TEXT_POWER)}
                    </span>
                    {getFieldDecorator("isPowerDividedToPlugs")(
                      <Radio.Group
                        disabled={isPartner}
                        onChange={(e) =>
                          this.setState({
                            isPowerDividedToPlugs: e.target.value,
                          })
                        }
                        value={this.state.isPowerDividedToPlugs}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Radio value={true}>
                            {freire(texts.IS_DIVIDE_POWER)}
                          </Radio>
                          <Radio value={false}>
                            {freire(texts.FIXED_POWER_BETWEEN_PLUGS)}
                          </Radio>
                        </div>
                      </Radio.Group>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row
              style={{ display: "flex", alignItems: "flex-end" }}
              gutter={16}
            >
              <Col span={12}>
                <Form.Item label={freire(texts.VISIBILITY)}>
                  {getFieldDecorator("visibility")(
                    <Select
                      disabled={loading || isPartner}
                      placeholder={freire(texts.SELECT_VISIBILITY_TYPE)}
                    >
                      <Option value={"all"}>{freire(texts.ALL)}</Option>
                      <Option value={"groups"}>{freire(texts.GROUPS)}</Option>
                      <Option value={"none"}>{freire(texts.HIDDEN)}</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: 0, marginBottom: 24 }} />
          </Show>

          <span style={{ color: "#fd5876", fontWeight: "bold" }}>
            {freire(texts.COUPONS_PAYMENT)}
          </span>
          <Row style={{ marginTop: 8 }}>
            <span style={{ fontWeight: "bold" }}>{freire(texts.CHARGE)}</span>
          </Row>

          <Row gutter={24} style={{ marginTop: 8 }}>
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("paymentCharge.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || isPartner}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          paymentCharge: {
                            value: 0,
                            method: "",
                            description: "",
                          },
                          idleFee: {
                            enabled: false,
                            value: 0,
                            gracePeriod: "",
                            chargePeriod: "",
                            wayToCharge: WayToChargeIdleFee.NONE,
                          },
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.PAYMENT_CHARGE)}
                </span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("acceptCoupon", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || isPartner}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_COUPONS_CHARGE)}
                </span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("posPayment", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || isPartner}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.POS_PAYMENT)}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={!paymentCharge?.enabled ? { display: "none" } : {}}
          >
            <Col span={24}>
              <Form.Item label={freire(texts.METHOD)}>
                {getFieldDecorator("paymentCharge.method")(
                  <Radio.Group
                    disabled={loading || isPartner}
                    onChange={() =>
                      this.props.form.resetFields([
                        "paymentCharge.value",
                        "paymentCharge.period",
                        "paymentCharge.timeWindow",
                        "paymentCharge.perTime",
                      ])
                    }
                    buttonStyle="solid"
                  >
                    <Radio.Button value="kWh">
                      {freire(texts.METHOD_KWH)}
                    </Radio.Button>
                    <Radio.Button value="Time">
                      {freire(texts.METHOD_TIME)}
                    </Radio.Button>
                    <Radio.Button value="Fixo">
                      {freire(texts.METHOD_FIXED)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                style={
                  paymentCharge?.method === "Time" || !paymentCharge?.enabled
                    ? { display: "none" }
                    : {}
                }
                label={`${freire(texts.VALUE)}${
                  paymentCharge?.method !== ""
                    ? paymentCharge?.method === "kWh"
                      ? ` ${freire(texts.OF_KWH)}`
                      : ` ${freire(texts.FIXED)}`
                    : ""
                } (${selectedCompanyCurrency})`}
              >
                {getFieldDecorator("paymentCharge.value", {
                  normalize: (input) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(
                  <Input
                    disabled={loadingPaymentChargeValue || isPartner}
                    placeholder={freire(texts.VALUE_EX)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <div
            style={paymentCharge?.method !== "Time" ? { display: "none" } : {}}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label={freire(texts.METHOD_CHARGE)}>
                  {getFieldDecorator("paymentCharge.period")(
                    <Radio.Group
                      disabled={loading || isPartner}
                      buttonStyle="solid"
                    >
                      <Radio.Button value="relative">
                        {freire(texts.METHOD_TIME_RELATIVE)}
                      </Radio.Button>
                      <Radio.Button value="fixed">
                        {freire(texts.METHOD_TIME_FIXED)}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item label={freire(texts.VALUE)}>
                  {getFieldDecorator("paymentCharge.value", {
                    normalize: (input) => {
                      return input ? formatarMoeda(String(input)) : "0,00";
                    },
                  })(
                    <Input
                      style={{ minWidth: "9ch" }}
                      disabled={loadingPaymentChargeValue}
                      placeholder={freire(texts.VALUE_EX)}
                    />
                  )}
                </Form.Item>
              </Col>
              <div className="payment-period">
                {freire(texts.PAYMENT_PERIOD)}
              </div>
              <div className="div-payment-description">
                <Col span={4}>
                  <div style={{ marginTop: 6, minWidth: "6ch" }}>
                    {freire(texts.METHOD_DESCRIPTION)}
                  </div>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator("paymentCharge.perTime")(
                      <Input
                        style={{ minWidth: "6ch" }}
                        disabled={loadingPaymentChargeValue || isPartner}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item>
                    {getFieldDecorator("paymentCharge.timeWindow")(
                      <Radio.Group
                        disabled={loading || isPartner}
                        buttonStyle="solid"
                      >
                        <Radio.Button value="min">
                          {freire(texts.METHOD_TIME_MIN)}
                        </Radio.Button>
                        <Radio.Button value="hour">
                          {freire(texts.METHOD_TIME_HOUR)}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </div>
            </Row>
            <Row>
              <div className="box-payment-description">
                <div className="box-icon-info">
                  <Icon type="info" className="icon-info" />
                </div>
                <div>
                  <div>{freire(texts.PAYMENT_CHARGE_RESUME)}</div>
                  <div>{this.getSummary()}</div>
                </div>
              </div>
            </Row>
          </div>

          <Row
            style={
              paymentCharge?.enabled ? { marginTop: 15 } : { display: "none" }
            }
          >
            <span style={{ fontWeight: "bold" }}>{freire(texts.IDLE_FEE)}</span>
          </Row>

          <Row
            gutter={24}
            style={
              paymentCharge?.enabled ? { marginTop: 8 } : { display: "none" }
            }
          >
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("idleFee.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || isPartner}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_IDLE_FEE)}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={!idleFee?.enabled ? { display: "none" } : { marginTop: 8 }}
          >
            <Col span={8}>
              <Form.Item
                label={freire(
                  texts.VALUE_PER_MINUTE(
                    formatters.currency(
                      freire.userLanguage,
                      this.props.station.currency
                    )(null)
                  )
                )}
              >
                {getFieldDecorator("idleFee.value", {
                  normalize: (input) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(
                  <Input
                    disabled={isPartner}
                    type="text"
                    placeholder={freire(texts.VALUE_EX)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.GRACE_PERIOD)}>
                {getFieldDecorator("idleFee.gracePeriod")(
                  <Input
                    disabled={isPartner}
                    type="number"
                    min={1}
                    placeholder={freire(texts.GRACE_PERIOD_MINUTES)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 12 }}>
            <span style={{ fontWeight: "bold" }}>{freire(texts.RESERVE)}</span>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("canReserve", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || isPartner}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          payment: { enabled: false, value: 0 },
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_RESERVE)}
                </span>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("payment.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || !canReserve || isPartner}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          payment: { value: 0 },
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.PAYMENT_RESERVE)}
                </span>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={freire(texts.RESERVE_VALUE_IN(selectedCompanyCurrency))}
              >
                {getFieldDecorator("payment.value", {
                  normalize: (input) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(
                  <Input
                    disabled={
                      loading || (payment && !payment.enabled) || isPartner
                    }
                    placeholder={freire(texts.VALUE_EX)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: 0, marginBottom: 24 }} />
          <TitleTopicEditDrawer title={freire(texts.OTHER_SETTINGS)} />
          <Show when={"edit-test-station"}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="">
                  {getFieldDecorator("test", {
                    valuePropName: "checked",
                  })(
                    <Switch
                      disabled={loading || isPartner}
                      checkedChildren={freire(texts.YES)}
                      unCheckedChildren={freire(texts.NO)}
                    />
                  )}
                  <span style={{ marginLeft: 10 }}>
                    {freire(texts.TEST_STATION)}
                  </span>
                </Form.Item>
              </Col>
            </Row>
          </Show>

          <Show when={"edit-controller-station"}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      {freire(texts.END_CHARGE_LABEL)}
                      <Popover
                        placement="topLeft"
                        title={freire(texts.LABEL)}
                        trigger="hover"
                        content={
                          <div style={{ width: 400 }}>
                            <Alert
                              message={freire(texts.END_CHARGE_LABEL_INFO)}
                              type="warning"
                              showIcon
                            />
                            <img
                              style={{ width: "100%", marginTop: 20 }}
                              src={imgController}
                              alt="info"
                            />
                          </div>
                        }
                      >
                        <Icon type="question-circle-o" />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator("controller")(
                    <Input
                      disabled={isPartner}
                      placeholder={freire(texts.BRAND_EX)}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Show>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={freire(texts.QUALIFICATION_DATE)}>
                {getFieldDecorator("connectedDate", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <DatePicker
                    disabled={isPartner}
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={freire(texts.SELECT_DATE_TIME)}
                    format="L LTS"
                    showTime={{
                      defaultValue: moment("23:59:59", "HH:mm:ss"),
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_TYPE)}>
                {getFieldDecorator("stationTypeID")(
                  <Select disabled={loading || isPartner}>
                    {listTypes.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_MODEL)}>
                {getFieldDecorator("stationModelID")(
                  <Select disabled={loading || isPartner}>
                    {listModels.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_FLAG)}>
                {getFieldDecorator("stationFlags.Flag")(
                  <Select disabled={isPartner} allowClear>
                    {stationFlags.map((flag) => (
                      <Option key={flag} value={flag}>
                        {flag}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_PARKING_SPOT)}>
                {getFieldDecorator("stationParkingSpot")(
                  <InputNumber
                    disabled={isPartner}
                    min={0} // o zero é usado como valor default no banco
                    style={{ width: "100%" }}
                    placeholder={freire(texts.EX_PARAM("2"))}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label={freire(texts.STATION_LOCAL)}>
                {getFieldDecorator("stationLocalID")(
                  <Select disabled={loading || isPartner}>
                    {listLocals.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    {freire(texts.FLAG_TITLE_RECHARGE)}
                    <TextToolTip
                      title={freire(texts.FLAG_TITLE_RECHARGE)}
                      icon="question-circle-o"
                      info={[
                        {
                          name: freire(texts.FLAG_ON_THE_GO),
                          title: freire(texts.FLAG_ON_THE_GO_TITLE),
                          info: freire(texts.FLAG_ON_THE_GO_INFO),
                        },
                        {
                          name: freire(texts.FLAG_DESTINATION),
                          title: freire(texts.FLAG_DESTINATION_TITLE),
                          info: freire(texts.FLAG_DESTINATION_INFO),
                        },
                        {
                          name: freire(texts.FLAG_HOME_BASED),
                          title: freire(texts.FLAG_HOME_BASED_TITLE),
                          info: freire(texts.FLAG_HOME_BASED_INFO),
                        },
                        {
                          name: freire(texts.FLAG_HUB),
                          info: freire(texts.FLAG_HUB_INFO),
                        },
                      ]}
                    />
                  </span>
                }
              >
                {getFieldDecorator("stationFlags.TypeRecharge")(
                  <Select disabled={isPartner} allowClear>
                    <Option value="onTheGo">
                      {freire(texts.FLAG_ON_THE_GO)}
                    </Option>
                    <Option value="destination">
                      {freire(texts.FLAG_DESTINATION)}
                    </Option>
                    <Option value="homeBased">
                      {freire(texts.FLAG_HOME_BASED)}
                    </Option>
                    <Option value="hub">{freire(texts.FLAG_HUB)}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    {freire(texts.FLAG_TITLE_ACCESS)}
                    <TextToolTip
                      title={freire(texts.FLAG_TITLE_ACCESS)}
                      icon="question-circle-o"
                      info={[
                        {
                          name: freire(texts.FLAG_PUBLIC),
                          info: freire(texts.FLAG_PUBLIC_INFO),
                        },
                        {
                          name: freire(texts.FLAG_SEMI_PUBLIC),
                          info: freire(texts.FLAG_SEMI_PUBLIC_INFO),
                        },
                        {
                          name: freire(texts.FLAG_PRIVATE),
                          info: freire(texts.FLAG_PRIVATE_INFO),
                        },
                      ]}
                    />
                  </span>
                }
              >
                {getFieldDecorator("stationFlags.TypeAccess")(
                  <Select disabled={isPartner} allowClear>
                    <Option value="public">{freire(texts.FLAG_PUBLIC)}</Option>
                    <Option value="semiPublic">
                      {freire(texts.FLAG_SEMI_PUBLIC)}
                    </Option>
                    <Option value="private">
                      {freire(texts.FLAG_PRIVATE)}
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    {freire(texts.FLAG_TITLE_ROAD)}
                    <TextToolTip
                      title={freire(texts.FLAG_TITLE_ROAD)}
                      icon="question-circle-o"
                      info={[
                        {
                          name: freire(texts.FLAG_ROAD),
                          info: freire(texts.FLAG_ROAD_INFO),
                        },
                        {
                          name: freire(texts.FLAG_URBAN),
                          info: freire(texts.FLAG_URBAN_INFO),
                        },
                      ]}
                    />
                  </span>
                }
              >
                {getFieldDecorator("stationFlags.TypeRoad")(
                  <Select disabled={isPartner} allowClear>
                    <Option value="road">{freire(texts.FLAG_ROAD)}</Option>
                    <Option value="urban">{freire(texts.FLAG_URBAN)}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Show when={"edit-station-contract-model"}>
            <TitleTopicEditDrawer title={freire(texts.CONTRACT_MODEL)} />
            <Row gutter={[16, 8]}>
              <Col>
                <Form.Item label={freire(texts.TUPI_CHARGER)}>
                  {getFieldDecorator("tupiPlug")(
                    <RadioGroup
                      disabled={isPartner}
                      options={genericOptions(freire)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={freire(texts.TUPI_INSTALLER)}>
                  {getFieldDecorator("tupiInstall")(
                    <RadioGroup
                      disabled={isPartner}
                      options={genericOptions(freire)}
                      onChangeCallback={(e) =>
                        this.setState({ tupiInstall: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12} hidden={!tupiInstall}>
                <Form.Item label={freire(texts.PARTNER_INSTALLER)}>
                  {getFieldDecorator("partnerInstallerName")(
                    <Input
                      disabled={isPartner}
                      placeholder={freire(texts.PARTNER_NAME)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={freire(texts.CONTRACT_MODEL_LOWER_CASE)}>
                  {getFieldDecorator("contractModel")(
                    <TupiSelect
                      disabled={isPartner}
                      placeholder={freire(texts.SELECT)}
                      options={contractModelOptions(freire)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={freire(texts.REMUNERATION_ENERGY)}>
                  {getFieldDecorator("remunerationEnergy")(
                    <RadioGroup
                      disabled={isPartner}
                      onChangeCallback={(e) => {
                        this.setState({ remunerationEnergy: e.target.value });
                      }}
                      options={genericOptions(freire)}
                    />
                  )}
                </Form.Item>
              </Col>
              {remunerationEnergy === true && (
                <Col span={24}>
                  <EnergyRemunerationTable
                    data={this.props.station.company?.splitRule?.map(
                      (rule) => ({
                        key: rule._id,
                        receptor: rule.recipientName,
                        percentage: `${rule.percentage}%`,
                      })
                    )}
                  />
                </Col>
              )}
              <Col span={24}>
                <Form.Item label={freire(texts.REMUNERATION_MEDIA)}>
                  {getFieldDecorator("remunerationMedia")(
                    <RadioGroup
                      disabled={isPartner}
                      onChangeCallback={(e) => {
                        this.setState({ remunerationMedia: e.target.value });
                      }}
                      options={genericOptions(freire)}
                    />
                  )}
                </Form.Item>
              </Col>
              {remunerationMedia === true && (
                <Col span={24}>
                  <RemunerationMediaTable
                    disabled={isPartner}
                    data={splitRemunerationMedia || []}
                    updateData={(data) =>
                      this.setState({ splitRemunerationMedia: data })
                    }
                  />
                </Col>
              )}
              <Col span={12}>
                <Form.Item label={freire(texts.INSTALL_DATE)}>
                  {getFieldDecorator("installedAt")(
                    <DatePicker
                      disabled={isPartner}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={freire(texts.START_OPERATION)}>
                  {getFieldDecorator("operationStartedAt")(
                    <DatePicker
                      disabled={isPartner}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={freire(texts.START_CONTRACT)}>
                  {getFieldDecorator("contractStartedAt")(
                    <DatePicker
                      disabled={isPartner}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={freire(texts.END_CONTRACT)}>
                  {getFieldDecorator("contractEndedAt")(
                    <DatePicker
                      disabled={isPartner}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={freire(texts.FIRST_PAYMENT)}>
                  {getFieldDecorator("firstPaymentAt")(
                    <DatePicker
                      disabled={isPartner}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Show>
        </Form>

        <div
          style={
            isPartner
              ? { display: "none" }
              : {
                  zIndex: 9999,
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }
          }
        >
          <Button
            disabled={loading}
            onClick={() => onClose()}
            style={{ marginRight: 8 }}
          >
            {freire(texts.CANCEL)}
          </Button>
          <Button loading={loading} onClick={() => this.save()} type="primary">
            {freire(texts.SAVE)}
          </Button>
        </div>
      </Drawer>
    );
  }
}

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default withRouter(ComponentEditDrawer);
