export const phone = (phone) => {
  if (phone && typeof phone === "string") {
    const phoneFormat = /^\+\d{2}(\d{2})(\d{4,5})(\d{4})$/;
    const [, ddd, firstSubPhone, secondSubPhone] = phone.match(phoneFormat);

    return `(${ddd}) ${firstSubPhone}-${secondSubPhone}`;
  }

  return phone;
};
