import React from "react";
import { Icon } from "antd";
import * as texts from "../pages/Coupons/locales";

export const COUPON_STATUS = {
  EXPIRED: "expired",
  INACTIVE: "inactive",
  IN_USE: "inUse",
  AVAILABLE: "available",
};

export const COUPON_STATUS_TEXT = {
  [COUPON_STATUS.EXPIRED]: {
    color: "#F40000",
    text: texts.EXPIRED,
  },
  [COUPON_STATUS.INACTIVE]: {
    color: "#747277",
    text: texts.INACTIVE,
  },
  [COUPON_STATUS.IN_USE]: {
    color: "#22263F",
    text: texts.IN_USE,
  },
  [COUPON_STATUS.AVAILABLE]: {
    color: "#029B2D",
    text: texts.AVAILABLE,
  },
};

export const COUPON_TYPE = {
  FREE: "1",
  PERCENTAGE: "2",
  FIXED_VALUE: "3",
  CREDIT: "4",
};

export const COUPON_CODE_TYPE = {
  EQUALS: "equals",
  DIFFERENT: "different",
  AUTOLINKING: "autolinking",
};

export const StatusColor = {
  // OCPP status
  Available: {
    color: "#029b2d",
    name: "Disponível",
  },
  Charging: {
    color: "#7a84bf",
    name: "Carregando",
  },
  Reserved: {
    color: "#1890FF",
    name: "Reservada",
  },
  Faulted: {
    color: "#434341",
    name: "Com erro",
  },
  Unavailable: {
    color: "#f40000",
    name: "Indisponível",
  },
  Upkeep: {
    color: "#f40000",
    name: "Em manutenção",
  },
  Preparing: {
    color: "#e3e5ef",
    name: "Preparando",
  },
  Finishing: {
    color: "#e3efe6",
    name: "Finalizando",
  },
  SuspendedEV: {
    color: "#fc850d",
    name: "(EV) Suspenso",
  },
  SuspendedEVSE: {
    color: "#fc850d",
    name: "(EVSE) Suspenso",
  },

  // Outros status
  ThirdParty: {
    color: "#565559",
    name: "Terceiros",
  },
  Disabled: {
    color: "#CECECD",
    name: "Desativada",
  },
  Test: {
    color: "#CECECD",
    name: "Teste",
  },
  ToInstall: {
    color: "#CECECD",
    name: "Para instalar",
  },
  Loading: {
    color: "#E2E5E7", // same color of skeleton
    name: "Aguarde...",
  },
};

export function getStatusInfo(status) {
  if (!StatusColor[status]) {
    return {
      color: "#000000",
      name: "-------------",
    };
  }

  return StatusColor[status];
}

export function getTreeData({
  title = "Todas",
  loading = false,
  children = [],
}) {
  if (loading && children.length === 0) {
    return [
      {
        title: (
          <span>
            <Icon
              style={{
                marginRight: 8,
                color: "#1890ff",
              }}
              type="loading"
              spin
            />
            Carregando ...
          </span>
        ),
        selectable: false,
        checkable: false,
        isLeaf: true,
        key: "tree",
        value: "tree",
      },
    ];
  }

  if (children.length > 0) {
    return [
      {
        title,
        key: "tree",
        value: "tree",
        children,
      },
    ];
  }

  return [];
}
