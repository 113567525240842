import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  useEffect(() => {
    const path = window.location.href;

    let title = "Admin | Tupi";
    let favicon = "/favicon.png";
    let companyURL = "tupinamba";

    if (path) {
      if (path.includes("neocharge")) {
        title = "Admin | Neocharge";
        favicon = "/favicon-neocharge.png";
        companyURL = "neocharge";
      }

      if (path.includes("mobilize")) {
        title = "Admin | Mobilize";
        // favicon = "";
        companyURL = "mobilize";
      }
    }

    document.title = title;
    getFaviconEl().href = favicon;
    localStorage.setItem("companyURL", companyURL);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
