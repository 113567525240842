import React, { useState, Fragment } from "react";
import {
  Modal,
  message,
  Button,
  Divider,
  Tag,
  Icon,
  notification,
  Spin,
} from "antd";
import * as texts from "./locales";
import api from "../../../../services/api";
import { formatters } from "../../../../utils/formatters";
import "./index.css";
import { useOnUpdate } from "../../../../hooks";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { isBoolean } from "lodash";
import { disabledIdleFee } from "..";

const ModalBatchOperation = ({
  isOpen,
  closeModal,
  freire,
  stations: stationsProps,
  paymentCharge,
  country,
  acceptCoupon,
  posPayment,
  idleFee,
}) => {
  const history = useHistory();

  const DEFAULT_LOADING_STATIONS = false;
  const DEFAULT_STATIONS = [];
  const DEFAULT_EXPANDED = false;
  const DEFAULT_LOADING_SAVE = false;

  const [loadingStations, setLoadingStations] = useState(
    DEFAULT_LOADING_STATIONS
  );
  const [stations, setStations] = useState(DEFAULT_STATIONS);
  const [expanded, setExpanded] = useState(DEFAULT_EXPANDED);
  const [loadingSave, setLoadingSave] = useState(DEFAULT_LOADING_SAVE);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const resetState = () => {
    setLoadingStations(DEFAULT_LOADING_STATIONS);
    setStations(DEFAULT_STATIONS);
    setExpanded(DEFAULT_EXPANDED);
    setLoadingSave(DEFAULT_LOADING_SAVE);
  };

  async function getStations() {
    try {
      setLoadingStations(true);
      setStations([]);

      const { data } = await api.post(`connected_stations`, {
        page: 1,
        limit: 1000,
        station_ids: stationsProps.map((station) => station.stationID),
      });

      setLoadingStations(false);

      if (idleFee.enabled && !paymentCharge.enabled) {
        setStations(
          data.docs.filter((station) => station.paymentCharge?.enabled)
        );
      } else {
        setStations(data.docs);
      }
    } catch (error) {
      message.error(freire(texts.ERROR_GET_STATIONS));
      closeModal();
    }
  }

  useOnUpdate(() => {
    if (isOpen) {
      getStations();
    } else {
      resetState();
    }
  }, [isOpen]);

  const onUpdateBatchOperation = (userConfirmed) => async () => {
    try {
      const _paymentCharge = { ...paymentCharge };

      if (_paymentCharge.enabled && _paymentCharge.method === "") {
        message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT_METHOD));
        return;
      }

      _paymentCharge.value = parseInt(
        _paymentCharge?.value?.replace(/[.,]/g, "") ?? 0
      );
      if (_paymentCharge.enabled && _paymentCharge.value === 0) {
        message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT));
        return;
      }

      if (
        !userConfirmed &&
        (_paymentCharge.value === "" || _paymentCharge.value === 0)
      ) {
        setShowWarningModal(true);
        return;
      }

      const fieldsToBeUpdated = {
        country,
        stationIds: stations.map((station) => station._id),
      };

      if (isBoolean(idleFee.enabled)) {
        fieldsToBeUpdated.idleFee = {
          ...idleFee,
          value: parseInt(idleFee?.value?.replace(",", "") ?? 0),
          gracePeriod: idleFee.gracePeriod
            ? moment
                .duration(Number(idleFee.gracePeriod), "minutes")
                .toISOString()
            : "",
        };
      }

      if (isBoolean(_paymentCharge.enabled)) {
        if (!_paymentCharge.enabled) {
          fieldsToBeUpdated.idleFee = disabledIdleFee.idleFee;
        }
        fieldsToBeUpdated.paymentCharge = _paymentCharge;
      }

      if (isBoolean(acceptCoupon)) {
        fieldsToBeUpdated.acceptCoupon = acceptCoupon;
      }

      if (isBoolean(posPayment)) {
        fieldsToBeUpdated.posPayment = posPayment;
      }

      setLoadingSave(true);

      await api.put(`/batch-update/stations`, fieldsToBeUpdated);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.STATION_EDITED),
      });

      setTimeout(() => {
        // setLoadingSave(false);
        history.push("/dashboard/connected_stations");
      }, 1000);
    } catch (error) {
      const message = error?.response?.data?.message ?? error.message;

      console.error(message);
      console.error(error);

      setLoadingSave(false);

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message,
      });
    }
  };

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const renderStations = () => {
    const stationsToRender = expanded ? stations : stations.slice(0, 10);
    return stationsToRender.map((station) => (
      <tr className="stations-modal-batch" key={station.stationID}>
        <td className="id-column">{station.stationID}</td>
        <td className="name-column">{station.name}</td>
        <td className="payment-column">
          {station.paymentCharge && station.paymentCharge.enabled ? (
            <Fragment>
              <Tag color="blue">{station.paymentCharge.method}</Tag>
              <Tag style={{ marginTop: 5 }} color="green">
                {formatters.currency(
                  freire.userLanguage,
                  station.currency
                )(station.paymentCharge.value / 100)}
              </Tag>
            </Fragment>
          ) : (
            freire(texts.NO_PRICE)
          )}
        </td>
        <td className="idle-fee-column">
          {station.idleFee?.enabled ? (
            <>
              <Tag style={{ marginTop: 5 }} color="blue">
                {moment.duration(station.idleFee.gracePeriod).humanize()}
              </Tag>
              <Tag style={{ marginTop: 5 }} color="green">
                {formatters.currency(
                  freire.userLanguage,
                  station.currency
                )(station.idleFee.value / 100)}
              </Tag>
            </>
          ) : (
            freire(texts.NO_IDLE_FEE)
          )}
        </td>
      </tr>
    ));
  };

  const hasChangedStations =
    isBoolean(idleFee?.enabled) ||
    isBoolean(posPayment) ||
    isBoolean(acceptCoupon) ||
    isBoolean(paymentCharge?.enabled);

  const content = loadingStations ? (
    <Spin style={{ display: "block", marginTop: 25, marginBottom: 25 }} />
  ) : stations.length === 0 ? (
    <span className="warning-modal">
      <Icon
        type="warning"
        style={{ color: "#FCD429", fontSize: 20, paddingRight: 8 }}
      />
      {freire(texts.WARNING_NO_STATIONS_TO_BE_UPDATED)}
    </span>
  ) : (
    <div className="modal-batch">
      <div className="modal-batch-content">
        <div className="modal-batch-header">
          <span>{freire(texts.STATION_SELETION)}</span>
        </div>
        <div className="modal-batch-body">
          <table>
            <thead>
              <tr className="table-head">
                <th className="id-station">{freire(texts.ID)}</th>
                <th className="name-station">{freire(texts.NAME)}</th>
                <th className="payment-station">
                  {freire(texts.CURRENT_CHARG_PAYMENT)}
                </th>
                <th className="idle-fee-station">
                  {freire(texts.CURRENT_IDLE_FEE)}
                </th>
              </tr>
            </thead>
            <tbody>{renderStations()}</tbody>
          </table>

          {stations.length > 10 && (
            <Button type="link" onClick={toggleExpansion}>
              {expanded ? "Mostrar menos ▲" : "Mostrar mais ▼"}
            </Button>
          )}

          <span className="updated-price">{freire(texts.UPDATED_PRICE)}</span>

          <div className="updated-price-content">
            <div className="charge-model">
              <span
                style={{
                  fontWeight: "bold",
                  display: "block",
                  marginBottom: 16,
                }}
              >
                {freire(texts.PAYMENT_CHARGE)}
              </span>
              {paymentCharge && isBoolean(paymentCharge.enabled) ? (
                paymentCharge.enabled ? (
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 8 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{freire(texts.CHARGE_MODEL)}</span>
                      <span>
                        <Fragment>
                          <Tag color="blue">{paymentCharge.method}</Tag>
                        </Fragment>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{freire(texts.VALUE)}</span>
                      <span>
                        <Fragment>
                          <Tag color="green">
                            {formatters.getCurrencySymbol(
                              freire.userLanguage,
                              stations[0].currency
                            )}
                            {paymentCharge.value}
                          </Tag>
                        </Fragment>
                      </span>
                    </div>
                  </div>
                ) : (
                  <span>{freire(texts.NO_ACCEPT_PAYMENT)}</span>
                )
              ) : (
                <span>{freire(texts.NO_ALTERATIONS)}</span>
              )}
            </div>

            <div className="charge-model">
              <span
                style={{
                  fontWeight: "bold",
                  display: "block",
                  marginBottom: 16,
                }}
              >
                {freire(texts.COUPON_CHARGE)}
              </span>
              <span>
                <Fragment>
                  {isBoolean(acceptCoupon)
                    ? acceptCoupon
                      ? freire(texts.ACCEPT_COUPON)
                      : freire(texts.NO_ACCEPT_COUPON)
                    : freire(texts.NO_ALTERATIONS)}
                </Fragment>
              </span>
            </div>

            <div className="charge-model">
              <span
                style={{
                  fontWeight: "bold",
                  display: "block",
                  marginBottom: 16,
                }}
              >
                {freire(texts.POS_PAYMENT)}
              </span>
              <span>
                <Fragment>
                  {isBoolean(posPayment)
                    ? posPayment
                      ? freire(texts.POS_PAYMENT_METHOD)
                      : freire(texts.NO_POS_PAYMENT_METHOD)
                    : freire(texts.NO_ALTERATIONS)}
                </Fragment>
              </span>
            </div>

            <div className="charge-model">
              <span
                style={{
                  fontWeight: "bold",
                  display: "block",
                  marginBottom: 16,
                }}
              >
                {freire(texts.IDLE_FEE)}
              </span>

              {isBoolean(idleFee.enabled) ? (
                idleFee.enabled ? (
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 8 }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {freire(texts.GRACE_PERIOD)}
                      <Fragment>
                        <Tag style={{ marginBottom: 8 }} color="blue">
                          {idleFee.gracePeriod} min
                        </Tag>
                      </Fragment>
                    </span>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {freire(texts.VALUE_PER_MIN)}
                      <Fragment>
                        <Tag color="green">
                          {formatters.getCurrencySymbol(
                            freire.userLanguage,
                            stations[0].currency
                          )}
                          {idleFee.value}
                        </Tag>
                      </Fragment>
                    </span>
                  </div>
                ) : (
                  <span>{freire(texts.NO_ACCEPT_IDLE_FEE)}</span>
                )
              ) : (
                <span>{freire(texts.NO_ALTERATIONS)}</span>
              )}
            </div>
          </div>
        </div>

        <Divider style={{ margin: "30px 0 10px 0" }} />

        <span className="update-confirm">
          <Icon
            type="warning"
            style={{ color: "#FCD429", fontSize: 20, paddingTop: 4 }}
          />
          {hasChangedStations ? (
            <div>
              {freire(texts.UPDATE_CONFIRM)}
              <br />
              {freire(texts.PAYMENT_DISABLE_IDLE_FEE)}
            </div>
          ) : (
            freire(texts.NO_CHANGES)
          )}
        </span>
        <div className="modal-batch-footer">
          <Button
            disabled={loadingSave}
            onClick={closeModal}
            style={{ marginRight: "10px", width: "120px", height: "30px" }}
          >
            {freire(texts.CANCEL)}
          </Button>
          {hasChangedStations && (
            <Button
              disabled={loadingSave}
              type="primary"
              onClick={onUpdateBatchOperation(false)}
              style={{ width: "120px", height: "30px" }}
            >
              {freire(texts.UPDATE)}
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  const warningModal = (
    <Modal
      visible={showWarningModal}
      onCancel={() => setShowWarningModal(false)}
      footer={[
        <Button key="cancel" onClick={() => setShowWarningModal(false)}>
          {freire(texts.CANCEL)}
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={onUpdateBatchOperation(true)}
        >
          {freire(texts.YES_UPDATE)}
        </Button>,
      ]}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon type="warning" style={{ color: "#FCD429", fontSize: 40 }} />
        <span className="confirm-update-modal" style={{ marginLeft: 10 }}>
          {freire(texts.CONFIRM_UPDATE)}
        </span>
      </div>
    </Modal>
  );

  return (
    <Fragment>
      <Modal
        visible={isOpen}
        onCancel={closeModal}
        footer={null}
        width={1150}
        height={500}
      >
        {content}
      </Modal>
      {warningModal}
    </Fragment>
  );
};

export default ModalBatchOperation;
