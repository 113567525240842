import { QRCODE_TUPI } from "../components/QrCode/configs";

export type Brand = {
  alias: string;
  name: string;
  fullName?: string;
  emailIconUrl: string;
  emailBannerUrl: string;
  deepLinkSubdomain?: string;
  emailFrom: string;
  qrCodeTarget?: string;
  hideDisconnectedStations?: boolean;
  favicon: string;
};

export const BRAND = {
  alias: "tupinamba",
  name: "Tupi",
  deepLinkSubdomain: "link",
  fullName: "Tupi Energia",
  emailIconUrl:
    "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Ftupinamba-icon-min.png?alt=media&token=32bb59b6-9292-4dab-b0e7-66123e0556ed",
  emailBannerUrl:
    "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Ftupinamba-banner-min.png?alt=media&token=5c09f89f-9115-43a7-b372-58160fa1fc27",
  emailFrom: "suporte@email.tupinambaenergia.com.br",
  favicon: QRCODE_TUPI.logo,
} as const;

export type BrandAlias = typeof BRAND_ALIAS;

export const BRAND_ALIAS = BRAND.alias;
