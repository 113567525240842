import React, { Component } from "react";
//import "./index.css";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { FreireContext } from "../../utils/freireContext";
import * as texts from "./locales";

class PowerBiPainel extends Component {
  static contextType = FreireContext;

  render() {
    const { freire } = this.context;

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[freire(texts.ADMIN), freire(texts.PAINEL_POWER_BI)]}
        />
        <div>
          <iframe
            title="PowerBIAdmin"
            width="100%"
            height="800"
            src="https://app.powerbi.com/view?r=eyJrIjoiYjAxODUxNTgtZjBmNS00ZTI5LWJiZWItZTRkOTA2Njc2NmM2IiwidCI6IjE5MThmMzAzLWI5NTQtNDczNC05ZTQwLTIxZDg1YWE3MGUzNSJ9"
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        </div>
      </div>
    );
  }
}
export default withRouter(PowerBiPainel);
