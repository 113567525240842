import React, { Fragment, useEffect, useState } from "react";
import * as texts from "../../../locales";
import { Select, Input, Form, Col, Row, Switch, DatePicker } from "antd";
import { Show } from "../../../../../contexts/PermissionContext";
import { useFreire } from "../../../../../utils/freireContext";
import { useNewCampaignContext } from "../../context";
import MyDivider from "../../../../../components/MyDivider";
import moment from "moment";
import api from "../../../../../services/api";
import { sorter } from "../../../../../utils/sorter";
import {
  HAS_PARTNERSHIP,
  PARTNERSHIP,
} from "../../../../../components/StationEditDrawer/locales";

const { Option } = Select;

const CampaignInfo = ({ showDivider = true }) => {
  const { freire } = useFreire();

  const { form, userCompany, allCompanies } = useNewCampaignContext();
  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  const { getFieldDecorator } = form;

  const [hasPartners, setHasPartners] = useState(false);

  const [partner, setPartners] = useState();

  useEffect(() => {
    const getAllPartners = async () => {
      const { data } = await api.get("company/true");

      const companies = sorter(data, "companyName");

      const companiesNotHolding = companies.filter(
        (company) => !company.holding
      );

      setPartners(companiesNotHolding);
    };
    getAllPartners();
  }, []);

  return (
    <Fragment>
      <span style={{ color: "#fd5876", fontWeight: "bold" }}>
        {freire(texts.CAMPAIGN_INFO)}
      </span>
      <Row gutter={[16, 0]}>
        <Show when={"edit-coupom-company"}>
          <Col span={18}>
            <Form.Item label={freire(texts.COMPANY)}>
              {getFieldDecorator("company", {
                initialValue: userCompany?.id,
              })(
                <Select
                  showSearch
                  optionFilterProp="label"
                  disabled={allCompanies.length === 1}
                  placeholder={freire(texts.USER_COMPANY)}
                  onChange={() => form.resetFields(["companies"])}
                >
                  {allCompanies.map((company) => (
                    <Option
                      label={company.companyName}
                      key={company._id}
                      value={company._id}
                    >
                      {company.companyName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Show>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item
            labelAlign="left"
            className="custom-form-item"
            label={freire(HAS_PARTNERSHIP)}
          >
            <Switch
              checkedChildren={freire(texts.YES)}
              unCheckedChildren={freire(texts.NO)}
              onChange={(value) => {
                setHasPartners(value);
                if (!value) {
                  form.setFieldsValue({ campaignPartners: [] });
                }
              }}
              checked={hasPartners}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {hasPartners && (
            <Form.Item label={freire(PARTNERSHIP)}>
              {getFieldDecorator("campaignPartners")(
                <Select
                  mode="multiple"
                  placeholder={freire(texts.SELECT_PARTNERSHIP)}
                  showSearch
                >
                  {partner.map((part, index) => (
                    <Option
                      key={index}
                      label={part.companyName}
                      value={part._id}
                    >
                      {part.companyName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={18}>
          <Form.Item label={freire(texts.CAMPAIGN_NAME)}>
            {getFieldDecorator("name", {
              rules: [requiredFieldRule],
            })(<Input placeholder={freire(texts.CAMPAIGN_NAME_EX)} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator("enabled", {
              valuePropName: "checked",
              initialValue: true,
            })(
              <Switch
                checkedChildren={freire(texts.YES)}
                unCheckedChildren={freire(texts.NO)}
              />
            )}
            <span style={{ marginLeft: 8 }}>
              {freire(texts.STATUS_CAMPAIGN)}
            </span>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={freire(texts.EXPIRE_DATE_TIME)}>
            {getFieldDecorator("expireDate", {
              rules: [
                requiredFieldRule,
                {
                  validator: async (_, value, callback) => {
                    try {
                      if (value && moment().isAfter(value)) {
                        throw new Error(freire(texts.INSERT_DATE_TIME));
                      }
                    } catch (err) {
                      callback(err);
                    }
                  },
                },
              ],
            })(
              <DatePicker
                style={{ width: "316px" }}
                format="L LTS"
                placeholder={freire(texts.SELECT_DATE_TIME)}
                disabledDate={(current) =>
                  current && current < moment().subtract(1, "day").endOf("day")
                }
                showTime={{
                  defaultValue: moment("23:59:59", "HH:mm:ss"),
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      {showDivider && <MyDivider />}
    </Fragment>
  );
};

export default CampaignInfo;
