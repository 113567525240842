import {
  QRCODE_BYD,
  QRCODE_MOBILIZE,
  QRCODE_NEO,
  QRCODE_SHELL,
} from "../components/QrCode/configs";
import type { UnionToArray } from "../utils/types";
import { BRAND } from "./brand";

export const WHITE_LABELS = [
  {
    alias: "mobilize",
    name: "Mobilize",
    fullName: "Mobilize Charge Pass",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmobilize-icon-min.png?alt=media&token=0f82b7f9-23ca-44d1-ba63-8aebc292d54c",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmobilize-min.png?alt=media&token=0dbdb681-b4ff-45ea-9e54-ecdc3576d780",
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_MOBILIZE.logo,
  },
  {
    alias: "neocharge",
    name: "NeoCharge",
    fullName: BRAND.fullName,
    emailIconUrl: BRAND.emailIconUrl,
    emailBannerUrl: BRAND.emailBannerUrl,
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_NEO.logo,
  },
  {
    alias: "shell-recharge",
    name: "Shell Recharge",
    fullName: "Shell Recharge Latam",
    emailIconUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fshell-recharge-icon-min.png?alt=media&token=e25ce392-5b95-4c24-b0ab-f8c4f54e6db4",
    emailBannerUrl:
      "https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fshell-recharge-banner-min.png?alt=media&token=31588154-d827-4e88-b880-1edfa694c7c9",
    qrCodeTarget: "shell",
    emailFrom: "shellrecharge@raizen.com.br",
    favicon: QRCODE_SHELL.logo,
  },
  {
    alias: 'byd',
    name: 'BYD Recharge',
    fullName: 'BYD Recharge',
    emailIconUrl:
      'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbyd-recharge-icon-min.png?alt=media&token=faa08778-b9f8-422f-bf02-0d19f981d3d1',
    emailBannerUrl:
      'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fbyd-recharge-banner-min.png?alt=media&token=73d40040-b08d-4df2-b93c-a9081e4c1a8c',
    emailFrom: BRAND.emailFrom,
    favicon: QRCODE_BYD.logo,
  },
  // {
  //   alias: 'mercado-livre',
  //   name: 'Mercado Livre',
  //   emailIconUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmercadolivre-icon-min.png?alt=media&token=5eb538f8-1940-4948-be6f-5dd6420b5874',
  //   emailBannerUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fmercadolivre-min.png?alt=media&token=9a900343-047a-411d-8b20-8fa0385792b7',
  // },
  // {
  //   alias: 'zarp',
  //   name: 'Zarp',
  //   emailIconUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fzarp-icon-min.png?alt=media&token=5a2e9319-b4f4-404f-9959-ea96cefdd89b',
  //   emailBannerUrl:
  //     'https://firebasestorage.googleapis.com/v0/b/tupi-910b9.appspot.com/o/emailsIcons%2Fzarp-banner-min.png?alt=media&token=423ec35e-12e0-4986-9afa-334c8da188fb',
  // },
] as const;

export const WHITE_LABEL_ALIASES = WHITE_LABELS.map(
  (whiteLabel) => whiteLabel.alias
) as UnionToArray<(typeof WHITE_LABELS)[number]["alias"]>;

export type WhiteLabelAlias = (typeof WHITE_LABEL_ALIASES)[number];
