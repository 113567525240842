import {
  Button,
  Input,
  InputNumber,
  Form,
  Col,
  Row,
  Icon,
  Steps,
  notification,
  Select,
  Switch,
} from "antd";
import withPermission from "../../../contexts/PermissionContext/withPermission";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import SelectCountry from "../../../components/SelectCountry";
import SelectLocation from "../../../components/SelectLocation";
import { useCallback, useEffect, useState } from "react";
import { ICompany } from "../../../interfaces/company";
import { sorter } from "../../../utils/sorter";
import api from "../../../services/api";
import { COUNTRY_BR } from "../../../constants/countries";
import { getCountryCode, getStateAndCity } from "../../../utils/getLocationInfos";

function NewLocation(props: any) {
  const { freire } = useFreire();
  const { getFieldDecorator } = props.form;

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<ICompany[]>();

  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  const userHasOnlyOneCompany = companies?.length === 1;

  const initialCountry = userHasOnlyOneCompany
    ? companies[0]?.country
    : COUNTRY_BR;

  const selectedCountry = props.form.getFieldValue("country");

  const getCompanies = useCallback( async () => {
    setLoading(true);
    try {
      const { data: companies } = await api.get("company");

      const companiesNotHolding = sorter(
        companies.filter((company: ICompany) => !company.holding),
        "companyName"
      );
      setCompanies(companiesNotHolding);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COMPANIES),
      });
    } finally {
      setLoading(false);
    }
  }, [freire]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const onSelectCountry = () => {
    props.form.resetFields(["address2.city", "companyID"]);
  };

  const onSave = async () => {
    setLoading(true);
    props.form.validateFields(async (err: any, location: any) => {
      if (err) {
        setLoading(false);
        return;
      }

      if (location.address2){
        const { state, city } = getStateAndCity(location.country, location.address2);

        location.state = state;
        location.city = city;

        delete location.address2;
      }

      if (location.company_id) {
        const selectedCompany = companies?.find((company) => company._id === location.company_id)

        location.parent_company_id = selectedCompany?.parentCompanyID;
        location.currency = selectedCompany?.currency;
        location.language = selectedCompany?.language;
      }

      if(location.country) {
        location.country_code = getCountryCode(location.country);
      }

      try {
        await api.post("locations", location);
        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.LOCATION_ADD_SUCCESS),
        });
        props.history.push("/dashboard/list_locations");
      } catch (error: any) {
        const {
          response: {
            data: { message },
          },
        } = error;

        notification.error({
          message: freire(texts.WENT_WRONG),
          description: message || error.message,
        });
      } finally {
        setLoading(false);
      }
    })
  }

  return (
    <div className="container">
      <div className="contentFull">
        <Row>
          <Col span={4}>
            <Steps
              current={1}
              size="small"
              onChange={() => { }}
              direction="vertical"
            >
              <Steps.Step
                icon={<Icon type="profile" />}
                title={freire(texts.LOCATIONS)}
                description={freire(texts.ADD_LOCATION)}
              />
            </Steps>
          </Col>

          <Col span={20}>
            <Form wrapperCol={{ span: 24 }}>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item label={freire(texts.NAME)}>
                    {getFieldDecorator("name", {
                      rules: [requiredFieldRule],
                    })(<Input placeholder={freire(texts.NAME)} />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <SelectCountry
                    form={props.form}
                    label={texts.COUNTRY}
                    disabled={userHasOnlyOneCompany}
                    initialValue={initialCountry}
                    showWarn={false}
                    onSelect={onSelectCountry}
                  />
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.LATITUDE)}>
                    {getFieldDecorator("coordinates.latitude", { rules: [requiredFieldRule] })(
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={freire(texts.LATITUDE_EX)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.LONGITUDE)}>
                    {getFieldDecorator("coordinates.longitude", { rules: [requiredFieldRule] })(
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={freire(texts.LATITUDE_EX)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={freire(texts.ADDRESS)}>
                    {getFieldDecorator("address")(
                      <Input placeholder={freire(texts.ADDRESS)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <SelectLocation
                  form={props.form}
                  countryCode={selectedCountry}
                />
              </Row>

              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label={freire(texts.PHONE)}>
                    {getFieldDecorator("phone")(
                      <Input placeholder={freire(texts.PHONE)} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.PRIVATE_LOCATION_QUESTION)}>
                    {getFieldDecorator("private", {
                      valuePropName: "checked",
                    })(
                      <Switch
                        checkedChildren={freire(texts.YES)}
                        unCheckedChildren={freire(texts.NO)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.FREE_PARKING_QUESTION)}>
                    {getFieldDecorator("freeParking", {
                      valuePropName: "checked",
                    })(
                      <Switch
                        checkedChildren={freire(texts.YES)}
                        unCheckedChildren={freire(texts.NO)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={freire(texts.COMPANY)}
                    extra={freire(texts.SELECT_COMPANY_WARN)}
                  >
                    {getFieldDecorator("company_id")(
                      <Select
                        placeholder={freire(texts.SELECT_COMPANY)}
                        showSearch
                        optionFilterProp="children"
                      >
                        {companies &&companies.map((company) => (
                          <Select.Option
                            disabled={
                              company.country !== selectedCountry
                            }
                            key={company._id}
                            value={company._id}
                          >
                            {company.companyName}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <div className="footer">
            <Button
              loading={loading}
              onClick={onSave}
              type="primary"
            >
              {freire(texts.SAVE)}
            </Button>
          </div>
        </Row>
      </div>
    </div>
  )
}

const PageNewLocation = Form.create({ name: "form" })(NewLocation);
export default withPermission(PageNewLocation);
