import { Icon, Layout, Menu } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Link, Route, useLocation } from "react-router-dom";

import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import apiOcpp from "../../../services/apiOcpp";
import ChangeAvailability from "./ChangeAvailability";
import ChangeConfiguration from "./ChangeConfiguration";
import GetConfiguration from "./GetConfiguration";
import "./index.css";
import RemoteStartTransaction from "./RemoteStartTransaction";
import RemoteStopTransaction from "./RemoteStopTransaction";
import SelectStation from "./SelectStation";
import TriggerMessage from "./TriggerMessage";
import UpdateFirmware from "./UpdateFirmware";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import GetCompositeSchedule from "./GetCompositeSchedule";

const { Sider, Content } = Layout;

const Actions = ({ match, history }) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);

  const [loading, setLoading] = useState(true);
  const [stations, setStations] = useState(null);
  const [station, setStation] = useState("");
  const { freire } = useFreire();

  const { pathname } = useLocation();

  const actions = [
    {
      key: "changeAvailability",
      label: "Change Availability",
      permission: "send-change-availability",
    },
    {
      key: "triggerMessage",
      label: "Trigger Message",
      permission: "send-trigger-message",
    },
    {
      key: "remoteStartTransaction",
      label: "Remote Start Transaction",
      permission: "send-remote-start-transaction",
    },
    {
      key: "remoteStopTransaction",
      label: "Remote Stop Transaction",
      permission: "send-remote-stop-transaction",
    },
    {
      key: "changeConfiguration",
      label: "Change Configuration",
      permission: "send-change-configuration",
    },
    {
      key: "getConfiguration",
      label: "Get Configuration",
      permission: "send-get-configuration",
    },
    {
      key: "updateFirmware",
      label: "Update Firmware",
      permission: "send-update-firmware",
    },
    {
      key: 'getCompositeSchedule',
      label: 'Get Composite Schedule',
      permission: 'get-composite-schedule'
    }
  ];

  async function getConnectedStations(params = {}) {
    try {
      const { data: connectedStations } = await apiOcpp.get(`connected`);
      setLoading(false);
      setStations(connectedStations);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    var split = pathname.split("/actions/");
    if (split && split.length > 1) {
      var key = split[split.length - 1];

      setSelectedKey([key]);
      setOpenKeys([key]);
    }
  }, [pathname]);

  useEffect(() => {
    if (selectedKey.length > 0 && !stations) {
      getConnectedStations();
    }
  }, [selectedKey, stations]);

  function isSelected(key) {
    if (selectedKey.length > 0) {
      if (selectedKey[0] === key) {
        return (
          <Icon
            type="right"
            style={{
              color: "#747277",
              position: "absolute",
              right: 8,
            }}
          />
        );
      }
    }

    return null;
  }
  return (
    <Fragment>
      <div style={{ margin: 15 }}>
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.OCPP),
            freire(texts.OPERATIONS),
          ]}
        />
      </div>

      <BrowserRouter>
        <Layout
          className="actions"
          style={{ width: "60vw", minHeight: "100vh" }}
        >
          <Sider width={250} theme="dark" trigger={null}>
            <Menu
              openKeys={openKeys}
              selectedKeys={selectedKey}
              onOpenChange={(keys) => setOpenKeys(keys)}
              onSelect={(params) => setSelectedKey([params.key])}
              mode="inline"
              theme={"dark"}
            >
              {actions.map((action) => (
                <Show when={action.permission}>
                  <Menu.Item key={action.key}>
                    <Link to={`${match.url}/${action.key}`}>
                      <span>{action.label}</span>
                    </Link>
                    {isSelected(action.key)}
                  </Menu.Item>
                </Show>
              ))}
            </Menu>
          </Sider>
          <Layout>
            <Content style={{ padding: "0px  24px" }}>
              {selectedKey.length > 0 ? (
                <Fragment>
                  <SelectStation
                    onChange={(station) => setStation(station)}
                    stations={stations || []}
                    loading={loading}
                  />
                  <h3 style={{ color: "#FC385C" }}>
                    {freire(texts.SELECT_PARAMS)}
                  </h3>
                </Fragment>
              ) : (
                <span style={{ fontWeight: "bold", color: "#565559" }}>
                  {freire(texts.SELECT_PARAMS_EXEC)}
                </span>
              )}

              <Show when={"send-change-availability"}>
                <Route path={`${match.url}/changeAvailability`} exact>
                  <ChangeAvailability
                    redirectTo={(path) => history.push(path)}
                    connectors={station.connectors}
                    stationId={station.stationId}
                  />
                </Route>
              </Show>
              <Show when={"send-trigger-message"}>
                <Route path={`${match.url}/triggerMessage`} exact>
                  <TriggerMessage
                    redirectTo={(path) => history.push(path)}
                    stationId={station.stationId}
                    connectors={station.connectors}
                  />
                </Route>
              </Show>
              <Show when={"send-remote-start-transaction"}>
                <Route path={`${match.url}/remoteStartTransaction`} exact>
                  <RemoteStartTransaction
                    redirectTo={(path) => history.push(path)}
                    connectors={station.connectors}
                    stationId={station.stationId}
                  />
                </Route>
              </Show>
              <Show when={"send-remote-stop-transaction"}>
                <Route path={`${match.url}/remoteStopTransaction`} exact>
                  <RemoteStopTransaction
                    redirectTo={(path) => history.push(path)}
                    stationId={station.stationId}
                  />
                </Route>
              </Show>
              <Show when={"send-change-configuration"}>
                <Route path={`${match.url}/changeConfiguration`} exact>
                  <ChangeConfiguration
                    redirectTo={(path) => history.push(path)}
                    stationId={station.stationId}
                  />
                </Route>
              </Show>
              <Show when={"send-get-configuration"}>
                <Route path={`${match.url}/getConfiguration`} exact>
                  <GetConfiguration stationId={station.stationId} />
                </Route>
              </Show>
              <Show when={"send-update-firmware"}>
                <Route path={`${match.url}/updateFirmware`} exact>
                  <UpdateFirmware
                    redirectTo={(path) => history.push(path)}
                    stationId={station.stationId}
                  />
                </Route>
              </Show>
              <Show when={"get-composite-schedule"}>
                <Route path={`${match.url}/getCompositeSchedule`} exact>
                  <GetCompositeSchedule
                    redirectTo={(path) => history.push(path)}
                    stationId={station.stationId}
                    connectors={station.connectors}
                  />
                </Route>
              </Show>
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
    </Fragment>
  );
};

export default Actions;
