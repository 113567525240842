import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TablePagination, {
  paginationInitConfig,
} from "../../../components/TablePagination";
import api from "../../../services/api";
import "./index.css";
import * as texts from "../../ChargesHistory/ListChargesHistory/locales";
import { FreireContext } from "../../../utils/freireContext";
import moment from "moment";
import { formatters } from "../../../utils/formatters";

const { Option } = Select;

class ListPaymentsHistory extends Component {
  static contextType = FreireContext;

  constructor(props) {
    super(props);
    this.CSVLinkRef = React.createRef();
  }

  state = {
    name: "seila",
    loadingTable: false,
    loadingSave: false,
    data: [],
    charge: [],
    dateFiltrada: [],
    csvData: [],
    totalDateFiltrada: 0,
    pagination: paginationInitConfig,
    companys: [],
    stationsFilter: [],
    loadingStationsFilter: false,
    company: {},
    station: {},
    preventPageReload: false,
  };


  componentDidMount() {
    this.getcharges();
  }

  getConnectedStations = () => {
    this.setState({ loadingStationsFilter: true }, async () => {
      try {
        const { data } = await api.get(`select/connected_stations`);
        this.setState({ stationsFilter: data });
      } catch (error) {
      } finally {
        this.setState({ loadingStationsFilter: false });
      }
    });
  };

  getcharges = async (isDownload) => {
    const { freire } = this.context;
    let apiParams = {};

    if (isDownload === true) {
      apiParams = {
        ...this.props.form.getFieldsValue(),
        ...this.state.pagination,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      };
    } else {
      apiParams = {
        ...this.props.form.getFieldsValue(),
        ...this.state.pagination,
      };
    }

    try {
      this.setState({ loadingTable: true });

      const {
        data: { docs, totalDocs },
      } = await api.get(`paymentHistory`, {
        params: apiParams,
      });

      this.setState({
        dateFiltrada: docs,
        totalDateFiltrada: totalDocs,
        loadingTable: false,
      });

      return docs;
    } catch (error) {
      this.setState({ loadingTable: false });
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_PAYMENT),
      });
    }
  };

  filtroDate = async (nome) => {
    this.setState({ loadingTable: true });

    const { data: dateFiltrada } = await api.get("paymentHistory", {
      params: { name: nome },
    });

    this.setState({ dateFiltrada, loadingTable: false });
  };

  openDrawer = (charge) => {
    this.setState({ visible: true, charge });
  };

  closeDrawer = () => {
    this.setState({ visible: false });
  };

  filter = (byButton = true, event) => {
    if (event) {
      event.preventDefault();
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.getcharges(values);
      }
    });

    if (byButton) {
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: 1 },
      }));
    }
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.filter();
  };

  getColumns() {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.USER_EMAIL),
        dataIndex: "userEmail",
        key: "userEmail",
      },
      {
        title: freire(texts.STATION),
        dataIndex: "stationID",
        key: "stationID",
      },

      {
        title: freire(texts.VALUE),
        dataIndex: "value",
        key: "value",
        render: (value, data) => (
          <span>
            {formatters.currency(
              freire.userLanguage,
              data.currency
            )(value / 100)}
          </span>
        ),
      },
      {
        title: freire(texts.STATUS),
        dataIndex: "success",
        key: "success",
        render: (success) =>
          typeof success === "boolean" ? (
            <Icon
              style={{ color: success ? "green" : "red" }}
              type={success ? "check" : "close"}
            />
          ) : (
            "---"
          ),
      },
      {
        title: freire(texts.CREATION_DATA),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => <span>{moment(date).format("L LTS")}</span>,
      },
    ];
  }

  downloadExcel = async () => {
    const { freire } = this.context;

    try {
      const values = await this.getcharges(true);
      this.setState({ csvData: values });

      setTimeout(() => {
        if (this.CSVLinkRef.current) {
          this.CSVLinkRef.current.link.click();
        }
      }, 500);
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_DOWNLOAD_FILE),
      });
    }
  };

  getHeaders() {
    const { freire } = this.context;

    return [
      { label: freire(texts.EMAIL), key: "userEmail" },
      { label: freire(texts.STATION), key: "stationID" },
      { label: freire(texts.DATE), key: "createdAt" },
      { label: freire(texts.ENERGY), key: "value" },
      { label: freire(texts.STATUS), key: "success" },
    ];
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { stationsFilter, loadingStationsFilter } = this.state;
    const { freire } = this.context;

    const headers = this.getHeaders();
    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.PAYMENT_HISTORIC),
            freire(texts.LISTING),
          ]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Row gutter={[16, 8]}>
              <Col span={8}>
                <Form.Item label={freire(texts.STATION)}>
                  {getFieldDecorator("stationID")(
                    <Select
                      showSearch
                      allowClear
                      loading={loadingStationsFilter}
                      placeholder={
                        loadingStationsFilter
                          ? freire(texts.LOADING)
                          : freire(texts.SEARCH_BY_ID_OR_NAME_STATION)
                      }
                      optionFilterProp="children"
                      onChange={(value) => this.setState({ station: value })}
                      onDropdownVisibleChange={(opened) => {
                        if (opened && stationsFilter.length === 0) {
                          this.getConnectedStations();
                        }
                      }}
                    >
                      {stationsFilter.map((item, index) => {
                        return (
                          <Option key={index} value={item.stationID}>
                            {`${item.name} (${item.stationID})`}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className="footer">
            <Button
              style={{ marginRight: 10 }}
              onClick={(e) => this.clearFilters(e)}
            >
              {freire(texts.CLEAN)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <TablePagination
          style={{
            backgroundColor: "white",
            paddingBottom: 10,
            marginBottom: 20,
          }}
          loading={this.state.loadingTable}
          rowKey={(data) => data._id}
          dataSource={this.state.dateFiltrada}
          columns={columns}
          total={this.state.totalDateFiltrada}
          onPagination={(pagination) =>
            this.setState({ pagination }, () => this.filter(false))
          }
          current={this.state.pagination.page}
        />

        <div style={{ paddingLeft: 10, paddingBottom: 30 }}>
          <CSVLink
            style={{ display: "none" }}
            ref={this.CSVLinkRef}
            filename={freire(texts.CSV_FILE_PAYMENT)}
            className="btn btn-primary"
            data={this.state.csvData}
            headers={headers}
          />
          <span
            onClick={() => this.downloadExcel()}
            style={{
              color: "#1890ff",
              cursor: "pointer",
              paddingLeft: 10,
              fontSize: 17,
              border: "1px solid #1890ff",
              borderRadius: "6px",
              padding: "8px 16px",
              display: "inline-block",
            }}
          >
            {freire(texts.DOWNLOAD_CSV)}
            &nbsp;&nbsp;
            <Icon type="download" />
          </span>
        </div>

        <Drawer
          title={freire(texts.SEE_CHARGE)}
          width={500}
          onClose={this.closeDrawer}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="userID">
                  <Input
                    placeholder={freire(texts.NAME)}
                    value={this.state.charge.name}
                    onChange={(value) => {
                      let charge = this.state.charge;
                      charge.name = value.target.value;
                      this.setState({ charge });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={freire(texts.URL)}>
                  <Input
                    placeholder={freire(texts.URL)}
                    value={this.state.charge.url}
                    onChange={(value) => {
                      let charge = this.state.charge;
                      charge.url = value.target.value;
                      this.setState({ charge });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button 
              onClick={this.closeDrawer} 
              style={{ marginRight: 8 }}
            >
              {freire(texts.CANCEL)}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
const PagePaymentsHistory = Form.create({ name: "filters" })(
  ListPaymentsHistory
);
export default withRouter(PagePaymentsHistory);