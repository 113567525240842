import logoTupi from "../../assets/img/logo2.png";
import logoNeo from "../../assets/img/logo-neo2.png";
import logoShell from "../../assets/img/iconShell.png";
import logoMobilize from "../../assets/img/logo-mobilize2.png";
import logoBYD from "../../assets/img/logo-byd-qrcode.png";

const QRCODE_URL_PREFIX = process.env.REACT_APP_QRCODE_API_URL;

export const QRCODE_TUPI = {
  logo: logoTupi,
  sizeLogo: {
    width: 92,
    height: 92,
  },
  urlPrefix: QRCODE_URL_PREFIX,
};
export const QRCODE_NEO = {
  logo: logoNeo,
  sizeLogo: {
    width: 76.8,
    height: 76.8,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/neocharge`,
};
export const QRCODE_MOBILIZE = {
  logo: logoMobilize,
  sizeLogo: {
    width: 76.8,
    height: 76.8,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/mobilize`,
};
export const QRCODE_SHELL = {
  logo: logoShell,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/shell`,
};
export const QRCODE_BYD = {
  logo: logoBYD,
  sizeLogo: {
    width: 92,
    height: 92,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/byd`,
};
