export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const PAINEL_POWER_BI = {
  pt: "Painel Power BI",
  es: "Painel Power BI",
  en: "Power BI Dashboard",
};
