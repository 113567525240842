export const STATE = {
  pt: "Estado",
  es: "Estado",
  en: "State",
};

export const LOADING = {
  pt: "Carregando...",
  es: "Cargando...",
  en: "Loading...",
};

export const SELECT_STATE = {
  pt: "Selecione o estado",
  es: "Seleccione el departamento",
  en: "Select State",
};

export const CITY = {
  pt: "Cidade",
  es: "Ciudad",
  en: "City",
};

export const SELECT_CITY = {
  pt: "Selecione a cidade",
  es: "Seleccione la ciudad",
  en: "Select City",
};

export const PROVINCE = {
  pt: "Província",
  en: "Province",
  es: "Provincia",
};

export const SELECT_PROVINCE = {
  pt: "Selecione a província",
  en: "Select Province",
  es: "Seleccione la província",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa",
  en: "Ops",
};

export const ERROR_GET_STATES = {
  pt: "Erro ao buscar a lista de estados",
  en: "Error getting states list",
  es: "Error al obtener la lista de estados",
};

export const ERROR_GET_CITIES = {
  pt: "Erro ao buscar a lista de cidades",
  en: "Error getting cities list",
  es: "Error al obtener la lista de ciudades",
};
